import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getQueryString } from 'src/utils'
import { Button, CircularProgress, OutlinedInput, useMediaQuery } from "@material-ui/core"
import { Modal, Radio, Space } from 'antd';

import { useWeb3Context } from 'src/hooks/web3Context'
import useInvite from 'src/hooks/useInvite'

import InviteImg from 'src/assets/invite-bg.png'
import InviteAppImg from 'src/assets/invite-app-bg.png'

import './invite.scss'

export default function Invite() {

  const { t } = useTranslation()
	const {pathname} = useLocation()
	const { address, chainID, connect, disconnect } = useWeb3Context()
  const { inviterAddress, BindInviter } = useInvite()
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [radioShow, setRadioShow] = useState(true);
  const [loading, setLoading] = useState(false);
	const [inviteAddress, setInviteAddress] = useState('')
  const [radiovalue, setRadiovalue] = useState('customize')
  const [inputAddress, setInputAddress] = useState('')
  const [radioDisabled, setRadioDisabled] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  

  const defaultAddress = '0xCDf13985Af32f58a53804624c3DeD42EF89b4352'

  const handleBind = async () => {
    if(!address) {
      setIsModalVisible(false)
      try {
        await connect()
      } catch (error) {
        error && setIsModalVisible(true)
      }
      return
    }
    let bindAddress = ''

    if(radioShow) {
      bindAddress = radiovalue === 'default' ? defaultAddress : inputAddress
    } else {
      bindAddress = inviteAddress
      if(!inviteAddress) return
    }

    setLoading(true)
    setRadioDisabled(true)
    BindInviter(bindAddress).then(res => {
      res && setIsModalVisible(false)
    }).finally(() => {
      setLoading(false)
      setRadioShow(false)
      setRadioDisabled(false)
    })
  }

  const handleChange = (e) => {
    setRadiovalue(e.target.value)
    if(e.target.value === 'default') {
      setInputAddress(defaultAddress)
      setInputDisabled(true)
    } else {
      setInputDisabled(false)
      setInputAddress('')
    }
  }

  // useEffect(() => {
  //   !inviterAddress && setIsModalVisible(true)
  // }, [address])

  // useEffect(() => {
  //   setIsModalVisible(!inviterAddress)
  // }, [inviterAddress])

  // useEffect(() => {
	// 	const address = getQueryString('address')
  //   setInviteAddress(address || '')
	// }, [pathname])

  useEffect(() => {
		const queryAddress = getQueryString('address')
    setInviteAddress(queryAddress || '')

    if(queryAddress) {
      setIsModalVisible(!inviterAddress)
      // !inviterAddress && setIsModalVisible(true)
      setRadioShow(false)
    } else {
      setRadioShow(!inviterAddress)
      setIsModalVisible(!inviterAddress)
    }

	}, [pathname, address, inviterAddress])

  return (
    <Modal className='invite-modal' visible={isModalVisible}  title={null} footer={null} centered={true} closable={false}>
      <img src={isSmallScreen ? InviteAppImg : InviteImg} alt="doughnut" />

      <div className='invite-content flex flex-column flex-align-items-center'>
        {inviteAddress ? <div className='invite-address'>{inviteAddress}</div> : <div className='invite-address no-content'>--</div>}

        <div className='invite-desc' style={{width: 262}}>
          {t('invite.desc')}
        </div>

        {radioShow && <div className='radio-container fullwidth flex flex-column flex-justify-content-start'>
          <Radio.Group onChange={handleChange} value={radiovalue} disabled={radioDisabled}>
            <Space direction="vertical">
              <Radio value={'customize'}>{t('invite.customize')}</Radio>
              <Radio value={'default'}>{t('invite.default')}</Radio>
            </Space>
          </Radio.Group>

          <OutlinedInput
            className="address-input"
            disabled={inputDisabled}
            labelWidth={0}
            placeholder={t('invite.address')}
            value={inputAddress}
            onChange={e => setInputAddress(e.target.value)}
          />
        </div>}

        <div className='fullwidth flex flex-justify-content-center'>
          <Button
            className="invite-button"
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={handleBind}
          >
            {loading ? <CircularProgress size={22} /> : address ? t('common.confirm') : t('common.connectWallet')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
