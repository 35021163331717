import React from "react";
import "./coming.scss";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Close";

function Coming(props:any) {

    const handleClose = () => {
        props.closeComing()
    }

    return (
        <div className="coming-soon">
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            open={props.state}
            onClose={handleClose}
            message={
                <React.Fragment>
                    <p className="coming-content">Information</p>
                    <p className="coming-content">{props.tip || 'Coming Soon'}</p>
                </React.Fragment>
            }
            action={
                <React.Fragment>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
        </div>
    );
}

export default Coming;
