import { openWarningNotification } from '../utils/tip.js';

export function formatNum(val) {
    return val.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
}

export const addTokenToWallet = async (tokenAddress) => {
  if (window.ethereum) {
      try {
          await window.ethereum.request({
              method: "wallet_watchAsset",
              params: {
                  type: "ERC20",
                  options: {
                      address: tokenAddress,
                      symbol: 'alphaToken',
                      decimals: 9,
                      image: imageURL,
                  },
              },
          });
      } catch (error) {
          openWarningNotification(error.message)
      }
  }
};

export const getQueryString = (name) => {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	let r = window.location.search.substr(1).match(reg);
	if (r != null) {
			return decodeURIComponent(r[2]);
	};
	return null;
}

export const isMobile = () => {
    var browser={
        versions:function() {
          var u=navigator.userAgent, app = navigator.appVersion;
          return {//移动终端浏览器版本信息 
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          };
        }(),
        language:(navigator.browserLanguage || navigator.language).toLowerCase()
      }
      
      return (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad)
}
