import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { styled } from "@material-ui/core/styles";
import { trim, formatCurrency } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Zoom,
	Stack,
	Button,
	Tooltip,
	Popper,
	FormControl,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slider
} from "@material-ui/core";
import tipsIcon1 from "../../assets/icons/tips_light.png";
import tipsIcon2 from "../../assets/icons/tips_black.png";

import "./snowglobe.scss";
import "./snowglobe.app.scss";

import { useTranslation } from 'react-i18next';

function Snowglobe({ theme }) {
  const { t } = useTranslation();
	const tipsIcon = theme === "light" ? tipsIcon1 : tipsIcon2;
	const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
	const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

	const [amount, setAmount] = useState(1);
	const [rewardYield, setRewardYield] = useState(0.9);
	const [currentPrice, setCurrentPrice] = useState(10);
	const [futurePrice, setFuturePrice] = useState(100);
	const [silderVal, setSilderVal] = useState(1);

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = event => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const open = Boolean(anchorEl);
	const id = "ohm-popper";

	const isAppLoading = useSelector(state => state.app.loading);
	const stakingRebase = useSelector(state => {
		return state.app.stakingRebase;
	});
	const sohmBalance = useSelector(state => {
		return state.account.balances && state.account.balances.sohm ? state.account.balances.sohm : 0;
	});
	const marketPrice = useSelector(state => {
		return state.app.marketPrice > 0 ? trim(state.app.marketPrice, 2) : 10;
	});
	const stakingRebasePercentage = stakingRebase > 0 ? trim(stakingRebase * 100, 4) : 0.9;

	useEffect(() => {
		setCurrentPrice(marketPrice)
		setFuturePrice(marketPrice)
	}, [marketPrice])

	useEffect(() => {
		setRewardYield(stakingRebasePercentage)
	}, [stakingRebasePercentage])

	const setMax = (name) => {
		if (name == 'amount') {
			setAmount(sohmBalance);
		} else if (name == 'rewardYield') {
			setRewardYield(stakingRebasePercentage);
		} else if (name == 'currentPrice') {
			setCurrentPrice(marketPrice);
		} else if (name == 'futurePrice') {
			setFuturePrice(marketPrice);
		} else {
			return;
		}
	};

	const handleSilderChange = (event, newValue) => {
		if (typeof newValue === 'number') {
			setSilderVal(newValue);
		}
	};

	return (
		<div id="calculator-view" className="calculator-view">
			<div className="stake-head flex justify-content-start">
				<Typography variant="h2" className="head-text">{t('calculator.estimateYour')}</Typography>
			</div>

			<div className="stake-top">
				<div className="top-container flex flex-justify-content-between">
					<div className="top-item flex flex-column">
						<div className="top-label">
							{t('calculator.currentINDPrice')}
						</div>
						<div className="top-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{`$${marketPrice}`}</>}
						</div>
					</div>

					<div className="top-item flex flex-column">
						<div className="top-label">
							{t('calculator.nextRewardYied')}
						</div>
						<div className="top-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{`$${stakingRebasePercentage}`}%</>}
						</div>
					</div>

					<div className="top-item flex flex-column">
						<div className="top-label">
							{t('calculator.yoursINDBalance')}
						</div>
						<div className="top-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{`$${sohmBalance}`} MOS</>}
						</div>
					</div>
				</div>
			</div>

			<div className="stake-mid">
				<div className="mid-container flex flex-justify-content-between">
					<div className="mid-item flex flex-column">
						<div className="mid-label">
							{t('calculator.yourInitial')}
						</div>
						<div className="mid-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{trim(amount * currentPrice, 2)} USDT</>}
						</div>
					</div>

					<div className="mid-item flex flex-column">
						<div className="mid-label">
							{t('calculator.currentWealth')}
						</div>
						<div className="mid-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{trim(marketPrice, 2)} USDT</>}
						</div>
					</div>

					<div className="mid-item flex flex-column">
						<div className="mid-label">
							{t('calculator.INDRewards')}
						</div>
						<div className="mid-value">
							{isAppLoading ? <Skeleton width="150px" /> : <>{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) - amount, 2).toString()} MOS</>}
						</div>
					</div>

					<div className="mid-item flex flex-column">
						<div className="mid-label">
							{t('calculator.estimateReturn')}
						</div>
						<div className="mid-value">
							{isAppLoading ? <Skeleton width="150px" /> :
							  <>
									{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice, 2).toString()} USDT
								</>}
						</div>
					</div>

					<div className="mid-item flex flex-column lambos-number">
						<div className="mid-label" style={{ lineHeight: 'inherit' }}>
							{t('calculator.potentialNumber')}
						</div>
						<div className="mid-value">
							{isAppLoading ? <Skeleton width="150px" /> : 
								<>
								{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice / 200000, 2).toString()}
								</>}
						</div>
					</div>
				</div>
			</div>

			<div className="stake-input flex flex-justify-content-around">
				<div className="input-item">
					<Typography variant="h6" className="input-label">
						{t('calculator.sINDAmount')}
					</Typography>
					<OutlinedInput
						id="outlined-adornment-amount"
						autoFocus={true}
						type="number"
						placeholder="Enter MOS Amount"
						className="snowglobe-input"
						value={amount}
						onChange={e => setAmount(e.target.value >= 10000 ? 10000 : e.target.value)}
						labelWidth={0}
						endAdornment={
							<InputAdornment position="end">
								<Button variant="text" onClick={() => { setMax('amount') }}>
									{t('common.max')}
								</Button>
							</InputAdornment>
						}
					/>
				</div>

				<div className="input-item">
					<Typography variant="h6" className="input-label">
						{t('calculator.rewardYield')}
					</Typography>
					<OutlinedInput
						id="outlined-adornment-reward"
						type="number"
						placeholder="Enter Reward Yield (%)"
						className="snowglobe-input"
						value={rewardYield}
						onChange={e => setRewardYield(e.target.value >= 100 ? 100 : e.target.value)}
						// startAdornment={<InputAdornment position="start">$</InputAdornment>}
						labelWidth={0}
						endAdornment={
							<InputAdornment position="end">
								<Button variant="text" onClick={() => { setMax('rewardYield') }}>
									{t('common.current')}
								</Button>
							</InputAdornment>
						}
					/>
				</div>

				<div className="input-item">
					<Typography variant="h6" className="input-label">
						{t('calculator.purchasePrice')}
					</Typography>
					<OutlinedInput
						id="outlined-adornment-amount"
						type="number"
						placeholder="Enter buy price"
						className="snowglobe-input"
						value={currentPrice}
						onChange={e => setCurrentPrice(e.target.value >= 20000 ? 20000 : e.target.value)}
						// startAdornment={<InputAdornment position="start">$</InputAdornment>}
						labelWidth={0}
						endAdornment={
							<InputAdornment position="end">
								<Button variant="text" onClick={() => { setMax('currentPrice') }}>
									{t('common.current')}
								</Button>
							</InputAdornment>
						}
					/>
				</div>

				<div className="input-item">
					<Typography variant="h6" className="input-label">
						{t('calculator.futureINDMarket')}
					</Typography>
					<OutlinedInput
						id="outlined-adornment-future"
						type="number"
						placeholder="Enter future price"
						className="snowglobe-input"
						value={futurePrice}
						onChange={e => setFuturePrice(e.target.value >= 20000 ? 20000 : e.target.value)}
						// startAdornment={<InputAdornment position="start">$</InputAdornment>}
						labelWidth={0}
						endAdornment={
							<InputAdornment position="end">
								<Button variant="text" onClick={() => { setMax('futurePrice') }}>
									{t('common.current')}
								</Button>
							</InputAdornment>
						}
					/>
				</div>

				<div className="days-item" style={{width: '100%'}}>
					<Typography variant="h6" className="input-label" style={{textAlign: 'center'}}>
						{t('calculator.days')}
					</Typography>
					<OutlinedInput
						id="outlined-adornment-future"
						type="number"
						placeholder="Enter days"
						className="snowglobe-days-input"
						value={silderVal}
						onChange={e => setSilderVal(e.target.value)}
						// startAdornment={<InputAdornment position="start">$</InputAdornment>}
						labelWidth={0}
						endAdornment={
							<InputAdornment position="end">
								<Button variant="text">
									{t('common.days')}
								</Button>
							</InputAdornment>
						}
					/>
				</div>
			</div>

			<div className="stake-mid-app">
				<div className="mid-container flex flex-justify-content-between">
					<div className="flex flex-justify-content-between flex-column">
						<div className="mid-item flex flex-column">
							<div className="mid-label">
								{t('calculator.yourInitial')}
							</div>
							<div className="mid-value">
								{isAppLoading ? <Skeleton width="150px" /> : <>{trim(amount * currentPrice, 2)} USDT</>}
							</div>
						</div>

						<div className="mid-item flex flex-column">
							<div className="mid-label">
								{t('calculator.currentWealth')}
							</div>
							<div className="mid-value">
								{isAppLoading ? <Skeleton width="150px" /> : <>{trim(marketPrice, 2)} USDT</>}
							</div>
						</div>

						<div className="mid-item flex flex-column">
							<div className="mid-label">
								{t('calculator.INDRewards')}
							</div>
							<div className="mid-value">
								{isAppLoading ? <Skeleton width="150px" /> : <>{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) - amount, 2).toString()} MOS</>}
							</div>
						</div>
					</div>

					<div className="flex flex-justify-content-between flex-column">
						<div className="mid-item flex flex-column">
							<div className="mid-label">
								{t('calculator.estimatedReturn')}
							</div>
							<div className="mid-value">
								{isAppLoading ? <Skeleton width="150px" /> :
									<>
										{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice, 2).toString()} USDT
									</>}
							</div>
						</div>

						<div className="mid-item flex flex-column">
							<div className="mid-label" style={{ lineHeight: 'inherit' }}>
								{t('calculator.potentialNumber')}
							</div>
							<div className="mid-value" style={{ marginTop: 14 }}> 
								{isAppLoading ? <Skeleton width="150px" /> : 
									<>
									{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice / 200000, 2).toString()}
									</>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Snowglobe;
