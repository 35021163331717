import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { changeApproval, changeStake, doClaim } from "../../slices/StakeThunk";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useWeb3Context } from "src/hooks/web3Context";
import useStake from "src/hooks/useStake";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { error } from "../../slices/MessagesSlice";
import { useTranslation } from 'react-i18next';
import { openWarningNotification } from 'src/utils/tip'
import TimeCount from './TimeCount'
import Calculator from './Calculator'
import { trim, secondsUntilBlock, prettifySeconds, prettyVestingPeriod } from "src/helpers";
import { loadAppDetails } from "src/slices/AppSlice";
import { getBalances } from 'src/slices/AccountSlice';
import Loading from 'src/components/LoadingDialog/LoadingDialog'
import Tabs from 'src/components/Tabs/Tabs'
import CalculatorIcon from "src/assets/calculator.png";

import "./stake.scss";
import "./stake.mobile.scss";

const defaultData = {
  0: 0,
  30: 0,
  60: 0,
  90: 0,
}

function Stake({ theme }) {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const { lookInfo, initData, unlook } = useStake()
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const isSmallScreen = useMediaQuery("(max-width: 500px)");

  const [claimPending, setClaimPending] = useState(false);
  const [stakeApprovePending, setStakeApprovePending] = useState(false);
  const [unstakeApprovePending, setUnstakeApprovePending] = useState(false);
  const [stakePending, setStakePending] = useState(false);
  const [unstakePending, setUnstakePending] = useState(false);
  
  const [currentMode, setCurrentMode] = useState(true);
  const [activeDep, setActiveDep] = useState(0);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState("");

  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('loading');
  const [loadingTitle, setLoadingTitle] = useState('');

  useEffect(() => {
    // dispatch(loadAppDetails({ networkID: chainID, provider, time: activeDep }));
    initData(activeDep)
    }, [activeDep])

  const isAppLoading = useSelector(state => state.app.loading);
  // const currentIndex = useSelector(state => {
  //   return state.app.currentIndex;
  // });
  const warmupPeriod = useSelector(state => {
    return state.app.warmupPeriod && state.app.warmupPeriod[activeDep];
  });
  const fiveDayRate = useSelector(state => {
    return state.app.fiveDayRate || defaultData
  });
  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const lock = useSelector(state => {
    return state.account.lock && state.account.lock[activeDep];
  });
  const warmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.warmBalance
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm
  });
  const wsohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.wsohmBalance;
  });
  const stakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmStake;
  });
  const unstakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmUnstake;
  });
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase || defaultData;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY || defaultData
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakeingTotalAmount || defaultData;
  });
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (currentMode) {
      setQuantity(ohmBalance);
    } else {
      setQuantity(sohmBalance[activeDep]);
    }
  };

  const handleCloseLoading = () => {
    setVisible(false)
    setStatus('loading')
  }

  const onSeekApproval = async token => {
    setLoadingTitle(t('loading.approve'))
    setVisible(true)
    currentMode ? setStakeApprovePending(true) : setUnstakeApprovePending(true)
    const result = await dispatch(changeApproval({ address, token, provider, networkID: chainID, time: activeDep }));
    currentMode ? setStakeApprovePending(false) : setUnstakeApprovePending(false)
    if(result && result.payload) {
      if(result.payload.payload && result.payload.payload.balances && result.payload.payload.balances.ohm) {
        setVisible(false)
      } else {
        setStatus('error')
      }
    } else {
      setStatus('fail')
    }
  };

  const onChangeStake = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      dispatch(error("Please enter a value!"));
    } else {
      if(action === 'stake') {
        if(+quantity > +ohmBalance) {
          return openWarningNotification(t('common.insufficient'))
        }
        setStakePending(true)
      } else {
        if(+quantity > +sohmBalance[activeDep]) {
          return openWarningNotification(t('common.insufficient'))
        }
        setUnstakePending(true)
      }

      setLoadingTitle(currentMode ? t('loading.unity') : t('loading.ununity'))
      setVisible(true)
      const result = await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID, time: activeDep }));
      if(result && result.payload) {
        if(result.payload.payload && result.payload.payload.balances) {
          setVisible(false)
        } else {
          setStatus('error')
        }
      } else {
        setStatus('fail')
      }
      if(action === 'stake') {
        setStakePending(false)
      } else {
        setUnstakePending(false)
      }
    }
  };

  const onClaim = async () => {
    // eslint-disable-next-line no-restricted-globals
    await dispatch(doClaim({ address, provider, networkID: chainID, time: activeDep }));
  };


  const hasAllowance = useCallback(
    token => {
      if (token === "ohm") return stakeAllowance && +stakeAllowance[activeDep] ? +stakeAllowance[activeDep] > 10000000000000 : false
      if (token === "sohm") return unstakeAllowance && +unstakeAllowance[activeDep] ? +unstakeAllowance[activeDep] > 10000000000000 : false
      return 0;
    },
    [stakeAllowance, unstakeAllowance, activeDep],
  );

  let modalButton = [];

  modalButton.push(
    <Button variant="outlined" color="primary" className="connect-button" onClick={connect} key={1}>
      {t('connectWallet')}
    </Button>,
  );

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const vestingTime = (block) => {
    return prettifySeconds(block - (new Date().getTime() / 1000));
  };

  const epochDesc = (number) => {
    let desc = ''
    switch (currentLang) {
      case 'en':
      case 'sp':
        desc = `(${t('bond.lockDesc')}${number}${t('bond.epoch')})`
        break;
      case 'hk':
      case 'kr':
        desc = `(${number}${t('bond.epoch')}${t('bond.lockDesc')})`
        break;
    
      default:
        break;
    }
    return desc
  }

  const stakingRebasePercentage = trim(stakingRebase[activeDep] * 100, 4);
  
  const sohmBalanceValue = sohmBalance && +sohmBalance[activeDep] ? trim((stakingRebasePercentage / 100) * (+(sohmBalance[activeDep])), 4) : 0

  const warmBalanceValue = warmBalance && +warmBalance[activeDep] ? trim((stakingRebasePercentage / 100) * (+(warmBalance[activeDep])), 4) : 0

  const nextRewardValue = +(warmBalanceValue) + +(sohmBalanceValue) 

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  const handleClaim = () => {
    setClaimPending(true)
    unlook(activeDep, () => setClaimPending(false))
  }

  const tabOption = [
    {
      label: t('stake.unity'),
      value: true
    },
    {
      label: t('stake.ununity'),
      value: false
    },
  ]

  return (
    <div id="stake-view" className="stake-view">
      <Calculator open={open} close={() => setOpen(false)} />
      <Loading visible={visible} title={loadingTitle} close={handleCloseLoading} status={status} />

      <div className="unity-tab-container">
        <Tabs value={currentMode} option={tabOption} onChange={value => setCurrentMode(value)} />

        {/* <div className="unity-tab flex flex-align-items-center">
          <div className={`${currentMode ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrentMode(true)}>
            {t('stake.unity')}
          </div>
          <div className={`${!currentMode ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrentMode(false)}>
            {t('stake.ununity')}
          </div>
        </div> */}

        <div className="unity-calculator" onClick={() => setOpen(true)}>
          <img src={CalculatorIcon} alt="" />
        </div>
      </div>

      <div className="unity-top flex flex-justify-content-between">
        <div className="top-item color-bg-container flex flex-column flex-justify-content-center flex-align-items-center">
          <div className="item-label">
          {t('stake.APY')}
          </div>
          <div className="item-value">
            <Typography variant="h4" style={{ "word-break": "break-all" }}>
              {stakingAPY[activeDep] ? (
                new Intl.NumberFormat("en-US").format(stakingAPY[activeDep] * 100).length > 30 ?
                  new Intl.NumberFormat("en-US").format(stakingAPY[activeDep] * 100).substring(0, 30) + "...%" :
                  new Intl.NumberFormat("en-US").format(stakingAPY[activeDep] * 100) + " %"
              ) : (
                <Skeleton width="100px" />
              )}
            </Typography>
          </div>
        </div>

        <div className="top-item color-bg-container flex flex-column flex-justify-content-center flex-align-items-center">
          <div className="item-label">
            {t('stake.totalValueDeposited')}
          </div>
          <div className="item-value">
            <Typography variant="h4">
              {stakingTVL[activeDep] ? (
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(stakingTVL[activeDep])
              ) : (
                <Skeleton width="100px" />
              )}
            </Typography>
          </div>
        </div>

        <div className="lock-container">
          <div className="lock color-bg-container">
            <div className="balance-title fullwidth">{t('stake.lockBalance')}</div>

            <div className="balance-table">
              <div className="table-header flex flex-justify-content-between">
                <div className="header-item">{t('bond.pending')}</div>
                <div className="header-item">{t('bond.fullyVested')}</div>
              </div>

              <div className="table-body">
                <div className="item-container flex fullwidth flex flex-justify-content-between flex-align-items-center">
                  <div className="body-item flex flex-justify-content-center">
                    {((lookInfo && lookInfo.deposit) || (lookInfo && lookInfo.deposit === 0)) ? (lookInfo.deposit / Math.pow(10, 9)).toFixed(2) + ' APD' : <Skeleton width="50px" />}
                  </div>
                  <div className="body-item flex flex-justify-content-center">
                    {((lookInfo && lookInfo.endTime) || (lookInfo && lookInfo.endTime === 0)) ? lookInfo.endTime.toString() === '0' ? '0 Day' : vestingTime(lookInfo.endTime) : <Skeleton width="50px" />}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-justify-content-center">
              <Button
                className="claim-button"
                color="primary"
                variant="outlined"
                disabled={lookInfo && 
                  (Number(lookInfo.endTime.toString()) - (new Date().getTime() / 1000) >= 0 || lookInfo.endTime.toString() === '0')}
                onClick={handleClaim}
              >
                {claimPending ? <CircularProgress size={16} /> : t('common.claim')}
              </Button>
            </div>
            </div>
        </div>
      </div>

      <div className="unity-bot">
        <div className="mode-container flex flex-justify-content-center flex-align-items-center">
          <div className="mode-tab flex flex-justify-content-end">
            <div
              className={`${activeDep === 0 ? 'mode-item-active' : ''} mode-item flex flex-justify-content-center flex-align-items-center`}
              onClick={() => setActiveDep(0)}
            >
              {t('stake.demand')}
            </div>

            <div
              className={`${activeDep === 30 ? 'mode-item-active' : ''} mode-item flex flex-justify-content-center flex-align-items-center`}
              onClick={() => setActiveDep(30)}
            >
              30 {t('common.days')}
            </div>

            <div
              className={`${activeDep === 60 ? 'mode-item-active' : ''} mode-item flex flex-justify-content-center flex-align-items-center`}
              onClick={() => setActiveDep(60)}
            >
              60 {t('common.days')}
            </div>

            <div
              className={`${activeDep === 90 ? 'mode-item-active' : ''} mode-item flex flex-justify-content-center flex-align-items-center`}
              onClick={() => setActiveDep(90)}
            >
              90 {t('common.days')}
            </div>
          </div>

          <TimeCount />
        </div>

        <div className="bot-data flex flex-justify-content-between">
          
          <div className="data-list bg-container flex flex-column">
            <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                {t('stake.yourBalance')}
              </div>
              <div className="item-value">
                {!address ? '0 APD' : !ohmBalance && ohmBalance !== 0 ? <Skeleton width="80px" /> : <>{trim(ohmBalance, 4)} APD</>}
              </div>
            </div>

            {activeDep !== 0 && <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                <div>
                  {t('stake.yourWarmupBalance')}
                </div>
                <div style={{fontSize: 12}}>
                  {lock > 0 ? epochDesc(lock) : ''}
                </div>
              </div>
              <div className="item-value">
                {!address ? '0' : !warmBalance[activeDep] && warmBalance[activeDep] !== 0 ? <Skeleton width="80px" /> :
                  <>
                    {trim(warmBalance[activeDep], 4)} uAPD {warmBalance[activeDep] > 0 ? lock > 0 ? ` (${t('bond.locked')})` :
                      <Button
                        style={{ padding: "0 10px" }}
                        className="claim-button"
                        variant="outlined"
                        disabled={isPendingTxn(pendingTransactions, "ClaimsKARMA")}
                        onClick={() => {
                          onClaim()
                        }}
                      >
                        {txnButtonText(pendingTransactions, "ClaimsKARMA", t('common.claim'))}
                      </Button> : ""}
                  </>
                }
              </div>
            </div>}

            <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                {t('stake.yourStakedBalance')}
              </div>
              <div className="item-value">
                {!address ? '0 uAPD' : sohmBalance && (sohmBalance[activeDep]) ? trim(sohmBalance[activeDep.toString()], 4) + ' uAPD' : <Skeleton width="80px" />}
              </div>
            </div>

            <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                {t('stake.nextRewardYied')}
              </div>
              <div className="item-value">
                {!stakingRebasePercentage && stakingRebasePercentage !== 0 ? <Skeleton width="80px" /> : <>{new Intl.NumberFormat("en-US").format(stakingRebasePercentage)}%</>}
              </div>
            </div>

            <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                {t('stake.nextRewardAmount')}
              </div>
              <div className="item-value">
                {!address ? '0 uAPD' : !nextRewardValue && nextRewardValue !== 0 ? <Skeleton width="80px" /> : <>{trim(nextRewardValue,4)} uAPD</>}
              </div>
            </div>

            <div className="left-item flex flex-justify-content-between">
              <div className="item-label">
                {t('stake.ROI')}
              </div>
              <div className="item-value">
                {!fiveDayRate[activeDep] && fiveDayRate[activeDep] !== 0 ? <Skeleton width="80px" /> : <>{trim(fiveDayRate[activeDep] * 100, 4)}%</>}
              </div>
            </div>
          </div>

          <div className="unity-operate bg-container">
            <div className="operate-title">
              Demand Unity
            </div>

            <div>
              <OutlinedInput
                id="amount-input"
                type="number"
                // placeholder="Enter an amount"
                className="amount-input"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                labelWidth={0}
                endAdornment={
                  <InputAdornment position="end">
                    <Button variant="text" onClick={setMax} color="inherit" className="max-button">
                      {t('common.max')}
                    </Button>
                  </InputAdornment>
                }
              />

              {
                currentMode ? 
                address && hasAllowance("ohm") ? (
                  <Button
                    className="stake-button"
                    variant="outlined"
                    color="primary"
                    disabled={stakePending}
                    onClick={() => {
                      onChangeStake(currentMode ? "stake" : 'unstake');
                    }}
                  >
                    {stakePending ? <CircularProgress size={22} /> : t('stake.unity')}
                  </Button>
                ) : (
                  <Button
                    className="stake-button"
                    variant="outlined"
                    color="primary"
                    disabled={stakeApprovePending}
                    onClick={() => {
                      onSeekApproval("ohm");
                    }}
                  >
                    {stakeApprovePending ? <CircularProgress size={22} /> : t('common.approve')}
                  </Button>
                )
                :
                address && hasAllowance("sohm") ? (
                  <Button
                    className="stake-button"
                    variant="outlined"
                    color="primary"
                    disabled={unstakePending}
                    onClick={() => {
                      onChangeStake(currentMode ? "stake" : 'unstake');
                    }}
                  >
                    {unstakePending ? <CircularProgress size={22} /> : t('stake.ununity')}
                  </Button>
                ) : (
                  <Button
                    className="stake-button"
                    variant="outlined"
                    color="primary"
                    disabled={unstakeApprovePending}
                    onClick={() => {
                      onSeekApproval("sohm");
                    }}
                  >
                    {unstakeApprovePending ? <CircularProgress size={22} /> : t('common.approve')}
                  </Button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stake;
