import { ethers } from 'ethers'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { addresses } from 'src/constants';
import { IJsonRPCError } from 'src/slices/interfaces';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from './web3Context';
import { abi as OlympusStaking } from "../abi/OlympusStakingv2.json";
import { error } from "../slices/MessagesSlice";
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useInvite() {

    const { address, provider, chainID } = useWeb3Context()
	const [lookInfo, setLookInfo] = useState()
    
    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async (time = 0) => {
            if (provider && chainID && address) {
                try {
                    let stakingAddress
                    switch (time) {
                        case 0:
                            stakingAddress = addresses[chainID].STAKING_ADDRESS
                            break;
                        case 30:
                            stakingAddress = addresses[chainID].STAKING_ADDRESS3
                            break;
                        case 60:
                            stakingAddress = addresses[chainID].STAKING_ADDRESS6
                            break;
                        case 90:
                            stakingAddress = addresses[chainID].STAKING_ADDRESS9
                            break;
                        default:
                            stakingAddress = addresses[chainID].STAKING_ADDRESS
                            break;
                    }
                    const signer = provider.getSigner();
					const stakeContract = new ethers.Contract(stakingAddress as string, OlympusStaking, signer)
                    const info = await stakeContract.lookInfo(address)
                    setLookInfo(info)
                } catch (error) {
                    // console.log(error)
                }
            }
        },
        [address, provider, chainID])

    const unlook = useCallback(
        async (time, cb) => {
            const signer = provider.getSigner();
            let stakingAddress
            switch (time) {
                case 0:
                    stakingAddress = addresses[chainID].STAKING_ADDRESS
                    break;
                case 30:
                    stakingAddress = addresses[chainID].STAKING_ADDRESS3
                    break;
                case 60:
                    stakingAddress = addresses[chainID].STAKING_ADDRESS6
                    break;
                case 90:
                    stakingAddress = addresses[chainID].STAKING_ADDRESS9
                    break;
                default:
                    stakingAddress = addresses[chainID].STAKING_ADDRESS
                    break;
            }
            const stakeContract = new ethers.Contract(stakingAddress, OlympusStaking, signer)
            let result
            try {
                result = await stakeContract.unLook(address)
                await result.wait()
                return result
            } catch (e: any) {
                if (e.data) {
                    openWarningNotification(e.data.message)
                } else {
                    openWarningNotification(e.message)
                }
            } finally {
                cb && cb()
                if(result) {
                    openSuccessNotification("Successfully")
                    initData()
                }
            }
        },
        [address, provider, chainID],
    )

    return { lookInfo, initData, unlook }
}
