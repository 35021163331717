import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Zoom,
} from "@material-ui/core";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useBonds from "../../hooks/Bonds";
import "./choosebond.scss";
import "./choosebond.app.scss";
import { Skeleton } from "@material-ui/lab";
import ClaimBonds from "./ClaimBonds";
import RewardBonds from "./RewardBonds";
import _ from "lodash";
import { allBondsMap } from "src/helpers/AllBonds";
import { formatCurrency, trim } from "../../helpers";
import { useTranslation } from 'react-i18next';
import { useAddress } from "../../hooks/web3Context";


import BondLogo from "../../components/BondLogo";
import BondRedeem from "../Bond/BondRedeem";
import Reward from "../Bond/BondRedeemReward";
import BondPurchase from "../Bond/BondPurchase";

function ChooseBond({ bond }) {
  const { t } = useTranslation();
  const { address } = useAddress()
  const history = useHistory()
  // const { provider, address, chainID } = useWeb3Context();
  const [activeBtn, setActiveBtn] = useState('bond')
  const { bonds } = useBonds();
  const [activeIndex, setActiveIndex] = useState(0)

  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const isAppLoading = useSelector(state => state.app.loading);
  const isAccountLoading = useSelector(state => state.account.loading);

  // useEffect(() => {
  //   dispatch(calculateUserSuperBondDetails({ address, bonds[activeIndex], provider, networkID: chainID }));
  // // }, [activeIndex])
  
  const accountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.bonds) {
      if (state.account.bonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    return withInterestDue;
  });

  const superAccountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.superBonds) {
      if (state.account.superBonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.superBonds[bond]);
      }
    }
    return withInterestDue;
  });

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          if (state.bonding[bond].bond != "sohm_wbnb_lp_old") {
            tokenBalances += state.bonding[bond].purchased;
          }
        }
      }
      return tokenBalances;
    }
  });

  const handleBond = (index) => {
    setActiveIndex(index)
    setTimeout(() => {
      history.push(`/bond-detail?current=${index}`)
    }, 300);
  }

  return (
    <div id="choose-bond-view" className="choose-bond-view">
      {/* {!isSmallScreen && !isAccountLoading && !_.isEmpty(accountBonds) && <ClaimBonds activeBonds={accountBonds} />} */}

      <Zoom in={true}>
        <div className="bond-container">

          <div className="bond-head">
            {t('bond.bond1')}
          </div>

          <div className="bond-top flex flex-justify-content-start">
            <div className="bond-top-left flex flex-justify-content-start flex-align-items-center">
              <div className="left-item">
                <div className="left-label">
                  {t('bond.treasuryBalance')}
                </div>
                <div className="left-value">
                  {isAppLoading ? <Skeleton width="100px" /> : `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))}`}
                </div>
              </div>

              <div className="left-item">
                <div className="left-label">
                  {t('bond.INDPrice')}
                </div>
                <div className="left-value">
                  {isAppLoading ? <Skeleton width="100px" /> : `$${trim(marketPrice, 2)}`}
                </div>
              </div>
            </div>

            <div className="bond-top-right flex flex-justify-content-end flex-align-items-end">
              <div className={`bond-top-btn flex flex-justify-content-center flex-align-items-center ${activeBtn === 'bond' ? 'active' : ''}`} onClick={() => setActiveBtn('bond')}>{t('bond.bond')}</div>
              <div className={`bond-top-btn flex flex-justify-content-center flex-align-items-center ${activeBtn === 'redeem' ? 'active' : ''}`} onClick={() => setActiveBtn('redeem')}>{t('bond.redeem')}</div>
              <div className={`bond-top-btn flex flex-justify-content-center flex-align-items-center ${activeBtn === 'reward' ? 'active' : ''}`} onClick={() => setActiveBtn('reward')}>{t('bond.reward')}</div>
            </div>
          </div>

          <div className={`bond-bot flex ${activeBtn === 'bond' ? 'flex-justify-content-between' : 'flex-justify-content-center'}`}>
            {activeBtn === 'bond' && (
              <div className="bot-left">

                <div className="bond-table">
                  <div className="bond-table-header">
                    <div className="header-icon"></div>
                    <div className="header-bond">{t('bond.bond')}</div>
                    <div className="header-price">{t('bond.price')}</div>
                    {isSmallScreen ? <div className="header-price"></div> : null}
                  </div>

                  <div className="bond-table-body">
                    {
                      bonds.map((bond, index) => (
                        <div className={`body-item ${activeIndex === index ? 'body-item-active' : ''}`} onClick={() => setActiveIndex(index)}>
                          <div className="body-item-icon">
                            <BondLogo bond={bond} />
                          </div>
                          <div className="body-item-bond">{bond.name}</div>
                          <div className="body-item-price">$ {trim(bond.bondPrice, 2)}</div>
                          {isSmallScreen ? <div className="body-item-price">
                            <Button
                              variant="outlined"
                              color="primary"
                              id="bond-detail-btn"
                              className="transaction-button"
                              onClick={() => 
                                handleBond(index)
                              }
                            >
                              {t('bond.bond')}
                            </Button>
                          </div> : null}
                        </div>
                      ))
                    }
                  </div>
                </div>
              
                {/* <TableContainer>
                  <Table aria-label="Available bonds">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t('bond.bond')}</TableCell>
                        <TableCell align="left">{t('bond.price')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bonds.map(bond => (
                        <BondTableData key={bond.name} bond={bond} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </div>
            )}
            {!(isSmallScreen && activeBtn === 'bond') && <div className={`${activeBtn === 'bond' ? 'bot-right' : 'bot-right width100'}`}>
              {activeBtn === 'bond' && <BondPurchase bond={bonds[activeIndex]} slippage={0.5} recipientAddress={address} activeIndex={activeIndex} />}
              {activeBtn === 'redeem' && <BondRedeem bond={bonds[activeIndex]} />}
              {activeBtn === 'reward' && <Reward bond={bonds[activeIndex]} />}
            </div>}
          </div>
        </div>
      </Zoom>

      {/* {isSmallScreen && (
        <Box className="ohm-card-container" style={{marginBottom: 30, width: '100%'}}>
          <Grid container container spacing={2}>
            {bonds.map(bond => (
                <BondDataCard key={bond.name} bond={bond} />
            ))}
          </Grid>
        </Box>
      )}

      {isSmallScreen && !isAccountLoading && !_.isEmpty(accountBonds) && <ClaimBonds activeBonds={accountBonds} />}

      {!isAccountLoading && !_.isEmpty(superAccountBonds) && <RewardBonds activeBonds={superAccountBonds} />} */}
    </div>
  );
}

export default ChooseBond;
