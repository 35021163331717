import { useEffect } from 'react';
import * as echarts from 'echarts';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from 'axios'
import './chart.scss'

export default function LineChart() {
	const isSmallScreen = useMediaQuery("(max-width: 1341px)")

  const initChart = () => {
    let element = document.getElementById('line-chart-container');
		// element.style.width = isSmallScreen ? (window.innerWidth * 0.9) + 'px' : '100%'
    echarts.init(element).dispose();
    let myChart = echarts.init(element);
    const option = {
      color: ['#FFBF00'],
      grid: {
        top: isSmallScreen ? '10px' : '30px',
        left: '1%',
        right: '5%',
        bottom: 0,
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisTick: {     
            show: false
          },
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {     
            show: false
          },
          splitLine: {     
            show: false
          },
          axisLabel: {
            formatter: function() {
              return ''
            }
          }
        }
      ],
      series: [
        {
          name: 'Line 5',
          type: 'line',
          stack: 'Total',
          smooth: true,
          lineStyle: {
            width: 4
          },
          showSymbol: false,
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(249,135,94,0)'
              },
              {
                offset: 1,
                color: 'rgba(244,94,132,0.2)'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: [120, 202, 141, 334, 210, 20, 150]
        }
      ]
    };
		option && myChart.setOption(option);
  }

  // useEffect(() => {
  //   const url = 'https://io.dexscreener.com/u/chart/bars/bsc/0x7C1ef82d209235cE404b0c52C1F3F4AaCf74972a?from=1661644800000&to=1661694098000&res=1440&cb=2&q=0x55d398326f99059fF775485246999027B3197955'
  //   axios({
  //     url,
  //     method: 'get'
  //   }).then(res => {
  //     console.log(res)
  //   })
  // }, [])

  useEffect(() => {
    initChart()
  }, [isSmallScreen, isSmallScreen])

  return (
    <div id="line-chart-container"
      // style={{width: 670, height: 340}}
      style={{width: '100%', height: isSmallScreen ? 170 : 340}}
    >

    </div>
  )
}
