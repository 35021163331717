import { useTranslation } from 'react-i18next';
import { useWeb3Context } from "src/hooks/web3Context";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addresses, TOKEN_DECIMALS } from "src/constants";
import { isMobile } from 'src/utils'

import WalletIcon from 'src/assets/images/header/wallet-icon.png'
import APD from 'src/assets/ape.png'
import IAPD from 'src/assets/sapd.png'
import Charts from 'src/assets/images/header/Charts.webp'
import PancakeSwap from 'src/assets/images/header/PancakeSwap.webp'

import './wallet.scss'

export default function Wallet() {
  const { t } = useTranslation()
  const { connect, chainID, address } = useWeb3Context();
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");
  
  const OHM_ADDRESS = addresses[chainID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[chainID].SOHM_ADDRESS;
  const IOHM_ADDRESS = addresses[chainID].IOHM_ADDRESS;

  const swapUrl = 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a'
  const bscUrl = 'https://dexscreener.com/bsc/0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a'

  const userAddress = () => {
    let val = address
    if (isSmallScreen) {
      return val.slice(0, 2) + "***" + val.slice(-4);
    } else {
      return val.slice(0, 4) + "******" + val.slice(-6);
    }
  }

  const openUrl = (url) => {
    window.open(url)
  }

return (
    <div className="wallet-bt-out">
      {address && <div className="wallet-bt">
        <img src={WalletIcon} alt="" />
        <span className='Poppins'>{userAddress()}</span>
      </div>}
      {!address && <div className="wallet-bt" onClick={connect}>
        <span>{t("common.connectWallet")}</span>
      </div>}
      <div className="apd-popup">
        {/* <div className="item" onClick="handlerPath('/my-nft')">
          <img src="../public/images/header/mynft.png" alt="" />
          <div className="green">{t("title.desc10")}</div>
        </div> */}
        {isMobile && <div className="item" onClick={() => connect()}>
          <img src={WalletIcon} alt="" />
          <div className='green'>{t("common.connectWallet")}</div>
        </div>}

        <div className="item" onClick={() => openUrl(swapUrl)}>
          <img src={PancakeSwap} alt="" />
          <div className="green">Buy on PancakeSwap</div>
        </div>
        <div className="item" onClick={() => openUrl(bscUrl)}>
          <img src={Charts} alt="" />
          <div className="green">Charts</div>
        </div>
        {
          address && <>
        <div className="title" v-if="address">ADD TOKEN TO WALLET</div>
          
        <div className="item"
          onClick={addTokenToWallet("APD", OHM_ADDRESS, APD)}
        >
          <img src={APD} alt="" />
          <div className="green">APD</div>
          {/* <span>{APDBalance}</span> */}
          <span>--</span>
        </div>

        <div className="item"
          onClick={addTokenToWallet("uAPD", SOHM_ADDRESS, APD)}
        >
          <img src={APD} alt="" />
          <div className="green">UAPD</div>
          {/* <span>{UAPDBalance}</span> */}
          <span>--</span>
        </div>
        <div className="item"
          onClick={addTokenToWallet("iAPD", IOHM_ADDRESS, IAPD)}
        >
          <img src={IAPD} alt="" />
          <div className="green">IAPD</div>
          {/* <span>{IAPDBalance}</span> */}
          <span>--</span>
        </div>
          </>
        }
      </div>
    </div>
  )
}

const addTokenToWallet = (tokenSymbol, tokenAddress, tokenImage) => async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};