import { StableBond, LPBond, NetworkID, CustomBond } from "src/lib/Bond";
import { ReactComponent as USDCImg } from "src/assets/tokens/usdc.svg";
import { ReactComponent as SohmWbnbImg } from "src/assets/tokens/usdc-ind.svg";
import { ReactComponent as MosUSDT } from "src/assets/mos-usdt.svg";
import { ReactComponent as USDTICON } from "src/assets/USDT.svg";
import { abi as BondHelperContract } from "src/abi/BondWbnbHelper.json";
import { abi as BondOhmLusdContract } from "src/abi/bonds/OhmLusdContract.json";
import { abi as BondContract } from "src/abi/BondContract.json";
import { abi as BondaFTMbContract } from "src/abi/bonds/aFTMbContract.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as BondOhmLusdHelperContract } from "src/abi/BondHelper.json";
import { BigNumberish } from "ethers";
import { addresses } from "src/constants";
import { ReactComponent as aFTMbSVG } from "src/assets/tokens/aftmb.svg";
/*
 wftm :  0x6125B48843E1653869B18889e663852032C24cC0
  aftmb ： 0xBcFDBc89EB372112b5852325E13cf4eFE953E31b
  wftm_usdc_lp: 0x464FA33378806c5F714E9Cc8b7f4640fEC84f468
  bond : 0xf17339E2C87Ebd158857d2FE6e331Fc80d7A2c42
 */
export const USDT = new StableBond({
  name: "USDT",// "USDT",
  displayName: "USDT",//"USDT",
  bondToken: "USDT",//"USDT",
  bondIconSvg: USDTICON,
  isAvailable: true,
  bondContractABI: BondContract,
  bondHelperContractABI: BondHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xA21fd30ca7ec172996A7d895Bd1eCCbb7AA3d87d",
      reserveAddress: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      bondHelperAddress: "0x8F120b7d4b4b8a439cA6b436aF2C87412C07E82f",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x51eF6b11Bbac4F8Ae7beF9b5cd0908F2b3a3026A",
      reserveAddress: "0xc85a96ddF7f3B7fC48f00F86D507c0F970794D5a",
      bondHelperAddress: "0x51eF6b11Bbac4F8Ae7beF9b5cd0908F2b3a3026A",
    },
  },
});

export const APD_USDC = new LPBond({
  name: "APD_USDT_LP",
  displayName: "APD-USDT LP",
  bondToken: "APDUSDC",
  isAvailable: true,
  bondIconSvg: MosUSDT,
  bondContractABI: BondContract,
  reserveContract: ReserveOhmLusdContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x6D32a8217f2ABa9Bd3b568354AbCF4C7B4695165",
      reserveAddress: "0x7C1ef82d209235cE404b0c52C1F3F4AaCf74972a",
      bondHelperAddress: "0xD3047bAc8Da6c6EFaF538c843300311527fA95b6",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x08990705083fF7620afa6a986F2E64379Fe99896", //Bond
      reserveAddress: "0xd2B8d3A3AD8290e1d1dBd35C3D0A6dF5e1942130", //lp
      bondHelperAddress: "0x2574b6692318E59fE41a25304a5284d5F8391471", //bondHelper
    },
  },
  lpUrl:
    "https://app.sushi.com/add/0x383518188C0C6d7730D91b2c03a03C837814a899/0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
});

// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!
export const allBonds = [APD_USDC];
// export const allBonds = [APD_USDC, USDT];
// export const allBonds = [MOS_USDC, USDT, aFTMb];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
// console.log(allBondsMap);
export default allBonds;
