import { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import './menu.scss'

export default function Menu() {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const [value, setValue] = useState('')

  useEffect(() => {
    if (pathname.indexOf("dashboard") >= 0) {
      setValue('dashboard')
    }
    if (pathname.indexOf("unity") >= 0) {
      setValue('unity')
    }
    if (pathname.indexOf("bargain") >= 0) {
      setValue('bargain')
    }
    if (pathname.indexOf("doughnut") >= 0) {
      setValue('doughnut')
    }
    if (pathname.indexOf("nft") >= 0) {
      setValue('nft')
    }
  }, [pathname])

  const handleClick = (path, ifOpen) => {
    if(ifOpen) return window.open(path)
    setValue(path)
    history.push(`/${path}`)
  }

  return (
    <div className='topbar-menu'>
      <div className='menu flex flex-justify-content-around'>
        <div className={`${value === 'dashboard' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('dashboard')}>{t('menu.dashboard')}</div>
        <div className={`${value === 'unity' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('unity')}>Unity</div>
        <div className={`${value === 'bargain' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('bargain')}>Bargain</div>
        {/* <div className={`${value === 'nft' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('nft')}>NFT</div> */}
        <div className={`${value === 'doughnut' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('doughnut')}>Doughnut</div>
        <div className={`${value === 'news' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('https://apepark.money/news?utm_source=tokenpocket',true)}>News</div>
      </div>
    </div>
  )
}
