import { useState, useCallback } from 'react'
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, SvgIcon, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import OhmMenu from "./OhmMenu.jsx";
import ConnectMenu from "./ConnectMenu.jsx";
import SwitchLang from "./SwitchLang.jsx";
import Logo from 'src/assets/images/nav-logo.webp'
import Coming from 'src/components/Coming'
import Menu from './Menu'

import WalletIcon from 'src/assets/images/line-wallet.png'
import MyWallet from './MyWallet'

import "./topmenu.scss";

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: "10px",
    },
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "#081429",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function TopBar({ theme, toggleTheme, handleDrawerToggle }) {
  const classes = useStyles();
  const isSmallerScreen = useMediaQuery("(max-width: 1200px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
  const [isActive] = useState();
  const [show, setShow] = useState(false)

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");

    if (currentPath.indexOf("website") >= 0 && page === "website") {
      return true;
    }
    if (currentPath.indexOf("dao") >= 0 && page === "dao") {
      return true;
    }
    if (currentPath.indexOf("apepark") >= 0 && page === "apepark") {
      return true;
    }
    return false;
  }, []);

  const close = () => {
    setShow(false)
  }

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar">
        <div className="logo-container">
          <div className='flex flex-align-items-center'>
            <img src={Logo} alt="logo" className='logo' />

            {!isSmallerScreen && <Menu />}
          </div>

          <div className='flex flex-align-items-center'>
            <div className='link-container flex'>
              <Link
                className='link-item'
                // onClick={() => window.open('https://apepark.money/')}
              >
                <Typography variant="h6" style={{color: '#FFF'}}>
                  APE DAO
                </Typography>
              </Link>

              <Link
                className={`link-item button-dapp-menu ${isActive ? "active" : ""}`}
                onClick={() => window.open('https://apepark.money/')}
              >
                <Typography variant="h6">
                  WEBSITE
                </Typography>
              </Link>

              <Link
                className='link-item'
                href="https://docs.apepark.money/" target="_blank">
                <Typography variant="h6">
                  Docs
                </Typography>
              </Link>

              {/* <Link
                className='link-item'
                href="https://docs.apepark.money/" target="_blank">
                <Typography variant="h6">
                  APD
                </Typography>
              </Link> */}
            </div>
            <MyWallet />

            {/* <div className='connect-btn flex flex-align-items-center'>
              <img src={WalletIcon} alt="" />
              <span>Wallet Connect</span>
            </div> */}
            <SwitchLang />
          </div>
        </div>
        {/* <Box display="flex"> */}
          {/* <OhmMenu /> */}
          {/* {!isVerySmallScreen && <OhmMenu />} */}
          {/* <ConnectMenu theme={theme} /> */}
          {/* <SwitchLang /> */}
        {/* </Box> */}
      </Toolbar>
      {isSmallerScreen && 
        <div className='fullwidth flex flex-justify-content-center'>
          <Menu />
        </div>
      }
    </AppBar>
    // <Coming state={show} closeComing={close} />
  );
}

export default TopBar;
