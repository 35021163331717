import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { trim, formatCurrency } from "../../helpers";
import { Modal } from 'antd';
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
	FormControl,
	InputAdornment,
	OutlinedInput,
	Slider
} from "@material-ui/core";

import Close from 'src/assets/close.png'
import './calculator.scss'

export default function Calculator({ open, close }) {

  const { t } = useTranslation();
	const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const [amount, setAmount] = useState(1);
	const [rewardYield, setRewardYield] = useState(0.9);
	const [currentPrice, setCurrentPrice] = useState(10);
	const [futurePrice, setFuturePrice] = useState(100);
	const [responseRate, setResponseRate] = useState(0);
	const [silderVal, setSilderVal] = useState(1);

	const stakingRebase = useSelector(state => {
		return state.app.stakingRebase;
	});
	const sohmBalance = useSelector(state => {
		return state.account.balances && state.account.balances.sohm ? state.account.balances.sohm : 0;
	});
	const marketPrice = useSelector(state => {
		return state.app.marketPrice > 0 ? trim(state.app.marketPrice, 2) : 10;
	});
	const stakingRebasePercentage = stakingRebase > 0 ? trim(stakingRebase * 100, 4) : 0.9;

	const setMax = (name) => {
		if (name == 'amount') {
			setAmount(sohmBalance);
		} else if (name == 'rewardYield') {
			setRewardYield(stakingRebasePercentage);
		} else if (name == 'currentPrice') {
			setCurrentPrice(marketPrice);
		} else if (name == 'futurePrice') {
			setFuturePrice(marketPrice);
		} else {
			return;
		}
	};

	useEffect(() => {
		setCurrentPrice(marketPrice);
	}, [marketPrice])

	const handleSilderChange = (event, newValue) => {
		if (typeof newValue === 'number') {
			setSilderVal(newValue);
		}
	};

	const testChange = (e) => {
		setAmount(e.target.value >= 10000 ? 10000 : e.target.value)
	}

  return (
    <Modal visible={open} width={720} closable={false} title={null} footer={null}>
        <Paper id="calculator-modal">
          <div className="calculator-title flex flex-justify-content-between flex-align-items-center">
            <div>{t('stake.calculator')}</div>
            <img src={Close} alt="" onClick={() => close()} />
          </div>
          <Box display="flex" className="snowglobe-container">
						<Grid container item className="header-container">
							<Grid item xs={12} sm={7}>
								<Grid container item>
									<Grid item xs={9}>
										<FormControl className="ohm-input" variant="outlined" color="primary">
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{/* sRICH AMOUNT */}
													{t('calculator.sAPDAmount')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-amount"
												autoFocus={true}
												type="number"
												placeholder="Enter RICH Amount"
												className="snowglobe-input"
												value={amount}
												onChange={testChange}
												// onChange={e => setAmount(e.target.value >= 10000 ? 10000 : e.target.value)}

												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('amount') }}>
															{t('common.max')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{/* REWARD YIELD (%) */}
													{t('calculator.rewardYield')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-reward"
												type="number"
												placeholder="Enter Reward Yield (%)"
												className="snowglobe-input"
												value={rewardYield}
												onChange={e => setRewardYield(e.target.value >= 100 ? 100 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('rewardYield') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{/* RICH PRICE AT PURCHASE (USDC) */}
													{t('calculator.purchasePrice')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-amount"
												type="number"
												placeholder="Enter buy price"
												className="snowglobe-input"
												value={currentPrice}
												onChange={e => setCurrentPrice(e.target.value >= 20000 ? 20000 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('currentPrice') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{/* FUTURE RICH MARKET PRICE (USDC) */}
													{t('calculator.futureINDMarket')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-future"
												type="number"
												placeholder="Enter future price"
												className="snowglobe-input"
												value={futurePrice}
												onChange={e => setFuturePrice(e.target.value >= 20000 ? 20000 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('futurePrice') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={3} className="sliderContainer">
										<Box className="sliderDesc" display="flex" alignItems="center" flexDirection="column"
											style={{ marginTop: `${isVerySmallScreen ? "16px" : "8px"}` }}>
											<Typography variant="h6">
												{silderVal}
											</Typography>
											<Typography variant="h6">
												{t('calculator.days')}
											</Typography>
										</Box>
										<Slider
											value={silderVal}
											min={1}
											step={1}
											max={365}
											// color="#07004A"
											orientation="vertical"
											onChange={handleSilderChange}
											valueLabelDisplay="auto"
											aria-labelledby="non-linear-slider"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={5}>
								<Box className="info" display="flex" justifyContent="center" flexDirection="column">
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.yourInitial')}
										</Typography>
										<Typography variant="h6">
											{trim(amount * currentPrice, 2)} USDT
										</Typography>
									</Box>
									{/* <Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											Current wealth
										</Typography>
										<Typography variant="h6">
											{trim(marketPrice, 2)} USDC
										</Typography>
									</Box> */}
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.estimatedReturn')}
										</Typography>
										<Typography variant="h6">
											{((+amount + (amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) - amount)) * futurePrice).toFixed(2)} USDT
											{/* {trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) - amount, 2).toString()} RICH */}
										</Typography>
									</Box>

									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.responseRate')}
										</Typography>
										<Typography variant="h6">
											{((((+amount + (amount * Math.pow((1 + rewardYield / 100), 3 * silderVal)) * futurePrice)/ (amount * currentPrice)) - 1) * 100).toFixed(2)} %
										</Typography>
									</Box>
									{/* <Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											Potential return
										</Typography>
										<Typography variant="h6">
											{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice, 2).toString()} USDC
										</Typography>
									</Box> */}
									{/* <Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<div>
											<Typography variant="h6">
												Potential number of lambos*
											</Typography>
											<Typography variant="h6" color="textSecondary">
												Lamborghini Huracán, no options
											</Typography>
										</div>
										<Typography variant="h6">
											{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice / 200000, 2).toString()}
										</Typography>
									</Box> */}
								</Box>
							</Grid>
						</Grid>
					</Box>
        </Paper>
    </Modal>
  )
}
