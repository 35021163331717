import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from "@material-ui/core";

import Close from 'src/assets/close.png'
import Dismiss from 'src/assets/dismiss.png'

import './loadingDialog.scss'

const LoadingDialog = ({ width = 275, visible, title, close, status = 'loading' }) => {

  const { t } = useTranslation()

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 80,
      }}
      spin
    />
  );

  return (
    <Modal width={width} title={null} footer={null} visible={visible} closable={false} centered={true} maskClosable={false} onCancel={close}>
      <div className='loading-header flex flex-justify-content-between'>
        <div>{title}</div>
        <img src={Close} alt="" onClick={close} />
      </div>

      <div className='loading-content flex flex-column flex-justify-content-center flex-align-items-center'>
        {
          status === 'loading' ?
          <>
            <Spin size="large" indicator={antIcon} style={{color: '#F60A66', marginBottom: 25}} />
            <div className='flex flex-justify-content-center'>{t('loading.tip')}</div>
          </>
          :
          <>
            <img src={Dismiss} alt="" />
            <div className='reject-tip'>
              {
                status === 'fail' ?
                <>{t('loading.fail')}</>
                :
                <>
                  {
                    t('loading.error').split('\n\r').map(i => (
                      <div>{i}</div>
                    ))
                  }
                </>
            }
            </div>
            <Button
              className="doughnut-button"
              variant="outlined"
              color="primary"
              onClick={close}
            >{t('loading.dismiss')}</Button>
          </>
        }
      </div>
    </Modal>
  );
};

export default LoadingDialog;
