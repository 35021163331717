import 'antd/dist/antd.css';

import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { getQueryString } from 'src/utils'
import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails, loadGovernanceDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, calculateUserSuperBondDetails } from "./slices/AccountSlice";

import { ChooseBond, TreasuryDashboard, Dashboard } from "./views";
// import Sidebar from "./components/Sidebar/Sidebar.jsx";
// import Sider from "./components/Sider/Sider.jsx";
// import SiderApp from "./components/Sider/SiderApp.jsx";
// import Wallet from "./components/Sider/Wallet.jsx";
// import WalletApp from "./components/Sider/WalletApp.jsx";
// import TopBar from "./components/TopBar/TopBar.jsx";
// import TopBarApp from "./components/TopBar/TopBarApp.jsx";
import TopMenu from "./components/TopMenu/TopMenu.jsx";
// import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import LoadingSplash from "./components/Loading/LoadingSplash";
import Messages from "./components/Messages/Messages";
import ComingSoon from "./components/Coming";
import Footer from "./components/Footer/Footer";
import NotFound from "./views/404/NotFound";
import InviteDialog from 'src/components/InviteDialog/Invite'
import { ethers } from "ethers"
import { dark as darkTheme } from "./themes/dark.js";
import i18n from './react-i18next-config'

import "./style.scss";

const DEBUG = false;
const Coming = lazy(() => import('./views/Coming/Coming'));
const Ido = lazy(() => import('./views/Ido/Ido'));
const Snowglobe = lazy(() => import('./views/Snowglobe/Snowglobe'));
const Stake = lazy(() => import('./views/Stake/Stake'));
const Bargain = lazy(() => import('./views/Bargain/Bargain'));
// const Bond = lazy(() => import('./views/Bonds/Bonds'));
const BondDetail = lazy(() => import('./views/Bonds/BargainDetail'));
const Invite = lazy(() => import('./views/Invite/Invite'));
// const Doughnut = lazy(() => import('./views/Doughnut/Doughnut'));
const Doughnut = lazy(() => import('./views/Doughnut'));
const NFT = lazy(() => import('./views/NFTStake/NFT'));
// const Governance = lazy(() => import('./views/governance/Governance'));
const TokenSwap = lazy(() => import('./views/TokenSwap/TokenSwap'));
const Redeem = lazy(() => import('./views/Redeem/Redeem'));
const GenesisChain = lazy(() => import('./views/GenesisChain/GenesisChain'))
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  // useGoogleAnalytics();
  const dispatch = useDispatch();
  const [theme, toggleTheme] = useTheme();
  const location = useLocation();
  const history = useHistory()
  const [showPerformance, setShowPerformance] = useState(false);
  const [walletVisible, setWalletVisible] = useState(false);
  const [showComing, setShowComing] = useState(false)
  const isSmallerScreen = useMediaQuery("(max-width: 958px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const address = useAddress()
  const [walletChecked, setWalletChecked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { bonds } = useBonds();
  
  const drawerWidth = 200;
  const transitionDuration = 969;

  const useStyles = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
      }),
      width: "100%",
      height: "100%",
      'overflow-x': 'none',
      // 'overflow-y': 'auto',
      // overflow: "auto",
      // maxWidth: 1341,
      // width: 1341,
      margin: '0 auto',
      // marginBottom: isSmallerScreen ? 66 : 0,
      // marginLeft: drawerWidth,
      // marginRight: isSmallerScreen ? 0 : 300,
      
      // paddingRight: 80
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: transitionDuration,
      }),
      marginLeft: 0,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  }));
  const classes = useStyles();

  async function loadDetails(whichDetails) {
    if (whichDetails === "app") {
      loadApp(provider);
    }
    if (whichDetails === "account") {
      loadAccount(provider);
    }
    // if (whichDetails === "account" && address && connected && isLoad) {
    //   loadAccount();
    // }
  }

  const loadApp = useCallback(
    () => {
      dispatch(loadAppDetails({ networkID: chainID, provider }));
      dispatch(loadGovernanceDetails({ networkID: chainID, provider }));
      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: null, provider, networkID: chainID, address }));
      });
      setIsLoad(true)
    },
    [connected, provider],
  );

  const loadAccount = useCallback(
    () => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(calculateUserSuperBondDetails({ address, bond, provider, networkID: chainID }));
      });
    },
    [connected, provider],
  );

  const switchPerformance = () => {
    setShowPerformance(!showPerformance)
  }

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    storeQueryParameters();
  }, []);

  useEffect(() => {
    loadDetails("app");
    // if (walletChecked) {
    //   loadDetails("app");
    // }
  }, [walletChecked, provider]);

  useEffect(() => {
    loadDetails("account");
    // if (connected && isLoad) {
    //   loadDetails("account");
    // }
  }, [connected, isLoad, provider]);

  let themeMode = darkTheme;

  useEffect(() => {
    themeMode = darkTheme;
  }, [theme]);

  // useEffect(() => {
	// 	const address = getQueryString('address')
  //   if(location.pathname === '/ido' && isSmallerScreen && address) {
  //     setWalletVisible(true)
  //   }
  // }, [location, isSmallerScreen]);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <ComingSoon state={showComing} closeComing={() => setShowComing(false)} />
      <div className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
        <Messages />
        <InviteDialog />
        <TopMenu />
        {/* todo */}
        {/* {isSmallerScreen ? <TopBarApp open={() => setWalletVisible(true)} /> : <TopMenu />} */}
        {/* {isSmallerScreen ? <SiderApp openComing={() => setShowComing(true)} /> : <Sider openComing={() => setShowComing(true)} />} */}

        {/* {isSmallerScreen ? <WalletApp visible={walletVisible} close={() => setWalletVisible(false)} /> : <Wallet />} */}

        {/* <nav className={classes.drawer}>
          <Hidden mdUp >
            <NavDrawer theme={theme} showPerformance={showPerformance} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          </Hidden>
          <Hidden smDown>
            <Sidebar theme={theme} showPerformance={showPerformance} />
          </Hidden>
        </nav> */}

        <div className={`${classes.content} ${isSmallerScreen && classes.contentShift} app-content`}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/coming" component={Coming} />
              <Route path="/ido" component={Ido} />
              <Route path="/invite" component={Invite} />
              {isSmallScreen ? <Route path="/bond-detail" component={BondDetail} /> : null}

              <Route exact path="/dashboard">
                <Dashboard showPerformance={showPerformance} />
              </Route>

              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              <Route path="/unity">
                <Stake theme={theme} />
              </Route>
              <Route exact path="/snowglobe">
                <Snowglobe />
              </Route>
              <Route exact path="/tokenSwap">
                <TokenSwap />
              </Route>
              <Route path="/doughnut" component={Doughnut} />
              {/* todo */}
              {/* <Route path="/nft" component={NFT} /> */}
              {/* <Route path="/genesis" component={GenesisChain} /> */}
              

              <Route path="/bargain">
                {/* {bonds.map(bond => {
                  return (
                    <Route exact key={bond.name} path={`/bonds/${bond.name}`} >
                      <Bond theme={theme} bond={bond} />
                    </Route>
                    );
                })} */}
                {/* <ChooseBond bond={bonds[0]} /> */}
                {/* <Bond bond={bonds[0]} /> */}
                <Bargain bond={bonds[0]} />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
        <Footer />

        {/* <img src={leftIcon} className="leftIcon"></img>
        <img src={rightIcon} className="rightIcon"></img> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
