// NOTE could get this from an outside source since it changes slightly over time
// export const BLOCK_RATE_SECONDS = 0.9; //FTM
export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    OHM_ADDRESS: "0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a", //
    IOHM_ADDRESS: "0x17f5F519D9aBB583019C4b02659ec98AEF9D8292",
    SOHM_ADDRESS: "0x28363611Ca9F5b91B56c57cBa09f3c14B4aEA493",
    SOHM_ADDRESS3: "0x7F68A277149ef2712E92C7043c76Fd370901e2a2",
    SOHM_ADDRESS6: "0xa374C51e6c5CAF0848023cF17d8B2393E4984071",
    SOHM_ADDRESS9: "0x40D1A597cae6B2f8AA578498b1B22E3cD0109a25",
    STAKING_ADDRESS: "0xb66a37fDf7E901aAfa4457F3500D92A5aDfB1952",
    STAKING_ADDRESS3: "0x56C678dA5D0f958749965E9834acbef86d115342",
    STAKING_ADDRESS6: "0x831edf250b97aEcDc7db4E2bd44D39Db860a21d8",
    STAKING_ADDRESS9: "0x964a08733A01e026A8D12B36Bd5623bE6B1EBe84",
    STAKING_HELPER_ADDRESS: "0x33a30D18FA36D929B86C5e76dfff2be9074c40a2",
    STAKING_HELPER_ADDRESS3: "0xCDBCDbEA4e399862EFD3Ce9871C7F320930C0367",
    STAKING_HELPER_ADDRESS6: "0xae9FFD33006d4517Bfcb14D7ffa64938c8A6ebb8",
    STAKING_HELPER_ADDRESS9: "0x7d2A08e8085cDF325DCa62C5Ac4e750aC5916560",
    DISTRIBUTOR_ADDRESS: "0xD426178c1E6dF84c6359C153529c12eEfa3792AC",
    BONDINGCALC_ADDRESS: "0x5E8F761cd33b034cdd4CD9d9619349EA6Fe02cc6",
    TREASURY_ADDRESS: "0xf982301aCb040bF1FeC75aA90e75f42aAefCB795",
    DAO_ADDRESS: '0xD4eC24Ae7088A434166b95FE3c9AF6Af324206A7',
    FEE_ADDRESS: '0xCDf13985Af32f58a53804624c3DeD42EF89b4352',
    LP_ADDRESS: '0x7C1ef82d209235cE404b0c52C1F3F4AaCf74972a',
    REDEEM_HELPER_ADDRESS: "0x05dc65d0DC84b8B21C200Dd1b67a6f977E8F51F5",
    MIGRATION_ADDRESS:'0xCe438141C641e4c002E4d04aF2aDBac4F24d63b6',
    TEAM_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    TEAM_AMOUNT_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    INVITE_ADDRESS: "0xF627F25e3C5D010EB21Cb6B191CBA07808a817ff",
    INVITE_ADDRESS2: "0x7338A570B697F90DeFB9D99aBd2Bc0d130Dd9B24",
    USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",
    IDO_ADDRESS: '0x5a0b36F2F9A95Dc20b4e6F2fAa1Be9fD9E114422',
    ALPHA_ADDRESS: '0xE7B9CEccB5260B976EC5Bd772A1d032278415de9',
    REWARD_ADDRESS: '0x775f9Ee2F61DEBd4b98B609Ea3DeD405429904b7',
    SWAP_ADDRESS: '0x44c53903263fD7D8e0Ec14b939547911A742De38',
    DOUGHNUT_ADDRESS: '0x9f019BF10d9b18662125800BbBd90822E7Ea2ccd'
  },
  97: {
    OHM_ADDRESS: "0x1cce14f238bf95fe5efc52bbc70fa00490e8aa69", //
    IOHM_ADDRESS: "0xb583925c3D3e9167a85C2441d66bfE30f0C6A884",
    SOHM_ADDRESS: "0x8785FAc24DEcDBe8022773114be09A98f0b642A5",
    SOHM_ADDRESS3: "0x9645C3712F874899D6085921Ce1e68f00C14C93f",
    SOHM_ADDRESS6: "0xF47d984fe4D7F57210426ED51518B3c217612b80",
    SOHM_ADDRESS9: "0x88be262DaD6C54cfb97eCB39eE81B5E8ed862953",
    STAKING_ADDRESS: "0x0cD829a73393c9F8309B42605528333b3067A93a",
    STAKING_ADDRESS3: "0x9FF4c988B159bb6884B0BDd197de3DDDa6EfA993",
    STAKING_ADDRESS6: "0xe4F3F7c99C450F6Cd10D5631265c3F0f86Ad3FF0",
    STAKING_ADDRESS9: "0xCF029C5889Ec4Bc8EF62Ca90870842Ff187e7FD4",
    STAKING_HELPER_ADDRESS: "0xdB908C8A4127824c9f02E84596A80F71FB5a045d",
    STAKING_HELPER_ADDRESS3: "0xd89643E61927e685DE4FA4D6bE1EFD22f4277d13",
    STAKING_HELPER_ADDRESS6: "0x6c091a95e845Cd9D62bb435A01cC23B50bADCf7f",
    STAKING_HELPER_ADDRESS9: "0x4B0650cCC6a7FFCc7448785236d80F160246913f",
    DISTRIBUTOR_ADDRESS: "0x455D1B9738eC95264530702FAe897484EFcc14da",
    BONDINGCALC_ADDRESS: "0xD4A89d12EDC16447D3AE6A421771D671bB708ae8",
    TREASURY_ADDRESS: "0x38464eADf006fe72c24e305B90F15b7818cd5885",
    DAO_ADDRESS: '0xCDf13985Af32f58a53804624c3DeD42EF89b4352',
    FEE_ADDRESS: '0xCDf13985Af32f58a53804624c3DeD42EF89b4352',
    LP_ADDRESS: '0xd2B8d3A3AD8290e1d1dBd35C3D0A6dF5e1942130',
    REDEEM_HELPER_ADDRESS: "0xCb0D0eFB8a01985a7D467168777B54180cAB3De5",
    MIGRATION_ADDRESS:'0x88394f8d645A1a53816BD513F09bd958aB7Ea815',
    TEAM_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    TEAM_AMOUNT_ADDRESS:'0x26f7d18d0eA80a27E5090A5BBC1f2a549A18faE4',
    INVITE_ADDRESS: "0xa7bd25AAE22d736D70Be5a4AbcB2Ce9Fb7681176",
    INVITE_ADDRESS2: "0xa7bd25AAE22d736D70Be5a4AbcB2Ce9Fb7681176",
    USDT_ADDRESS: "0xD8691a1A815a874E3094A8B5102C5cC9520cA8Ed",
    IDO_ADDRESS: '0xCe438141C641e4c002E4d04aF2aDBac4F24d63b6',
    ALPHA_ADDRESS: '0x29F02C7346B9e5808451128aDE2A4216eBd19008',
    REWARD_ADDRESS: '0x521FFa58d8841e7c7b2788BE4AB735bc29F70DA6',
    SWAP_ADDRESS: '0x0402A61761203A95Cd27f64F20B1CC3F63c17Feb',
    DOUGHNUT_ADDRESS: '0x2453a90229e221d0De1B60c323cc37417A7b13C8'
  }
};
