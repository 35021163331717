import { useHistory } from "react-router-dom"
import { Select, useMediaQuery } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';

import Coming from '../Coming'

import Logo from 'src/assets/images/nav-logo.webp'
// import Logo from 'src/assets/images/footer/Logo.png'
import Twitter from 'src/assets/images/footer/Twitter.png'
import Facebook from 'src/assets/images/footer/Facebook.png'
import Telegram from 'src/assets/images/footer/Telegram.png'
import Youtube from 'src/assets/images/footer/Youtube.png'
import TikTok from 'src/assets/images/footer/TikTok.png'
import Discord from 'src/assets/images/footer/Discord.png'
import Arrow from 'src/assets/select-arrow.png'

import './footer.scss'
import './footer.app.scss'
import { useState } from "react";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const currentLang = i18n.language;
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");

  const [showComing, setShowComing] = useState(false)

  const icons = [
    {
      src: Twitter,
      url: 'https://twitter.com/ApePark_DAO'
    },
    {
      src: Telegram,
      url: 'https://t.me/ApeParkDAO'
    },
    {
      src: TikTok,
      url: ''
    },
    {
      src: Discord,
      url: 'https://discord.gg/dyVejVgMZZ'
    }
  ]

  const handleChange = (lang) => {
    i18n.changeLanguage(lang)
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleChange('en')}>English</Menu.Item>
      <Menu.Item onClick={() => handleChange('hk')}>中文</Menu.Item>
    </Menu>
  );

  const openUrl = (url) => {
    if(url) return window.open(url)
    setShowComing(true)
  }

  return (
    <div id="footer-view">
      <Coming state={showComing} closeComing={() => setShowComing(false)} />

      <div className='footer-container'>

        <div className='footer-content flex flex-justify-content-between'>

          <div className='footer-left'>
            <div className="flex flex-column">
              <img src={Logo} alt="logo" className='logo' />

              <div className='desc'>{t('footer.desc')}</div>

              <div className='flex'>
                {
                  icons.map(item => (
                    <img src={item.src} alt="icon" className='icon' onClick={() => openUrl(item.url)} />
                  ))
                }
              </div>
            </div>

            {isSmallScreen && <div className='right-item language flex'>
              <div className='title Poppins'>Language</div>
              <Dropdown 
                overlay={menu}
                trigger="hover"
                placement="bottom"
                overlayClassName={`lang-dropdown ${isSmallScreen ? 'lang-dropdown-app' : ''}`}
              >
                <div className="lang-select flex flex-justify-content-between flex-align-items-center">
                  <div>{currentLang === 'en' ? 'English' : '中文'}</div>
                  <img src={Arrow} alt="" />
                </div>
              </Dropdown>
              {/* <Select
                variant="outlined"
                native
                defaultValue={currentLang}
                onChange={handleChange}
                IconComponent={() => (<img src={Arrow} alt="" style={{width: isSmallScreen ? 14 : 28, height: isSmallScreen ? 14 : 28}} />)}
                className="lang-select"
              >
                <option value={'hk'}>中文</option>
                <option value={'en'}>English</option>
              </Select> */}
            </div>}
          </div>

          <div className='footer-right flex flex-justify-content-between'>

            <div className='right-item defi flex'>
              <div className='title'>Defi</div>
              <div className='item'>
                <div onClick={() => history.push('/dashboard')}>Dashboard</div>
                <div onClick={() => history.push('/unity')}>Unity</div>
                <div onClick={() => history.push('/bargain')}>Bargain</div>
                <div onClick={() => history.push('/doughnut')}>Doughnut</div>
              </div>
            </div>

            <div className='right-item defi flex'>
              <div className='title'>GameFi</div>
              <div className='item'>
                <div onClick={() => openUrl('https://apepark.money/launchpad')}>Launchpad</div>
                <div onClick={() => openUrl('https://apepark.money/play')}>Play to Earn</div>
              </div>
            </div>

            <div className='right-item defi flex'>
              <div className='title'>Pool</div>
              <div className={`item active-item`}>
                <div onClick={() => openUrl('https://apepark.money/marketplace')}>Marketplace</div>
              </div>
            </div>

            {!isSmallScreen && <div className='right-item language flex'>
              <div className='title Poppins'>Language</div>

              <Dropdown 
                overlay={menu}
                trigger="hover"
                placement="bottom"
                overlayClassName={`lang-dropdown ${isSmallScreen ? 'lang-dropdown-app' : ''}`}
              >
                <div className="lang-select flex flex-justify-content-between flex-align-items-center">
                  <div>{currentLang === 'en' ? 'English' : '中文'}</div>
                  <img src={Arrow} alt="" />
                </div>
              </Dropdown>

              {/* <Select
                variant="outlined"
                native
                defaultValue={currentLang}
                onChange={handleChange}
                IconComponent={() => (<img src={Arrow} alt="" style={{diwth: 28, height: 28}} />)}
                className="lang-select"
              >
                <option value={'hk'}>中文</option>
                <option value={'en'}>English</option>
              </Select> */}
            </div>}

          </div>
        </div>

        <div className='footer-symbol flex flex-justify-content-center'>
          ©APE GAME, 2022 All rights reserved.
        </div>
      </div>
    </div>
  )
}
