import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  Button,
  OutlinedInput,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { allBondsMap } from "src/helpers/AllBonds";
import useInvite from '../../hooks/useInvite';
import { getQueryString } from '../../utils'
import { openWarningNotification } from '../../utils/tip.js';
import { trim } from "../../helpers";
import {list} from '../../config/black'
import { useTranslation } from 'react-i18next';
import { useAddress } from "src/hooks/web3Context";

import USDT from '../../assets/usdtIcon.png'

import "./dashboard.scss";
import "./dashboard.app.scss";

import Chart from './Chart'

function Dashboard({showPerformance}) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 1341px)");
  // Use marketPrice as indicator of loading.
  const dispatch = useDispatch();
	const {pathname} = useLocation()
  const address = useAddress();
  const { nodeAddress, gcAddress, total, group, AcceptInvite } = useInvite()
	const [visible, setVisible] = useState(false)
	const [invitePending, setInvitePending] = useState(false)
  // const [bondReward, setBondReward] = useState(0)
  // const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);

  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm;
  });
  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });
  const DAOBalance = useSelector(state => {
    return state.app.DAOBalance;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          if (state.bonding[bond].bond != "sohm_wbnb_lp_old") {
            tokenBalances = state.bonding[bond].purchased
          }
        }
      }
      // delete + 200000
      return tokenBalances
    }
  });
  // const bondReward = useSelector(state => {
  //   if (state.bonding.loading == false) {
  //     let bondRewards = 0;
  //     for (const bond in allBondsMap) {
  //       if (state.bonding[bond]) {
  //         bondRewards += state.bonding[bond].interestDue
  //       }
  //     }
  //     return bondRewards;
  //   }
  // });
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });
  const feeBalance = useSelector(state => {
    return state.app.FeeBalance;
  });
  const warmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.warmBalance;
  });

  const handleCloseModal = () => {
		setVisible(false)
	}

	const acceptInvite = () => {
		if(address) {
      if(address === inviteAddress) {
        openWarningNotification("Same Address")
        setVisible(false)
        return
      }
			setInvitePending(true)
			AcceptInvite(inviteAddress).then(tx => {
				setInvitePending(false)
				if(tx) {
					setVisible(false)
					window.location.href = window.location.href.split('?')[0]
				}
			})
		} else {
			openWarningNotification('Connect Wallet')
		}
	}

  return (
    <div className="dashboard-view">
      <div className="dashboard-title">
        <Typography variant="h2">{t('menu.dashboard')}</Typography>
      </div>
      <div className={`flex flex-justify-content-between ${isSmallScreen ? 'flex-wrap' : ''}`}>
        <div className="chart-container bg-container flex flex-column" style={{width: isSmallScreen ? '100%' : 670, height: isSmallScreen ? 170 : 340}}>
          <Typography variant="h6">{t('dashboard.marketPrice')}</Typography>
          <div className="flex flex-align-items-center">
            <Typography variant="h4">{trim(marketPrice, 2)}</Typography>
            <img src={USDT} alt="usdt icon"></img>
          </div>
          <Chart />
        </div>

        <div className="card-container flex flex-wrap">
          <div className="card-item bg-container flex flex-column flex-justify-content-center flex-align-items-center">
            <Typography variant="h6">{t('dashboard.treasuryBalance')}</Typography>
            <Typography variant="h5">
              {
                treasuryBalance || treasuryBalance === 0
                ?
                `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))}`
                :
                <Skeleton width="100px" />
              }
            </Typography>
          </div>
          
          <div className="card-item bg-container flex flex-column flex-justify-content-center flex-align-items-center">
            <Typography variant="h6">{t('dashboard.totalValueLocked')}</Typography>
            <Typography variant="h5">
              {stakingTVL || stakingTVL === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(stakingTVL)
                ) : (
                  <Skeleton width="100px" />
              )}
            </Typography>
          </div>

          <div className="card-item bg-container flex flex-column flex-justify-content-center flex-align-items-center">
            <Typography variant="h6">
              {t('dashboard.daoBalance')}
            </Typography>
            <Typography variant="h5">
              {
                (DAOBalance || DAOBalance === 0) && (marketPrice || marketPrice === 0) ?
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(DAOBalance * marketPrice) : <Skeleton width="100px" />
              }
            </Typography>
          </div>

          <div className="card-item bg-container flex flex-column flex-justify-content-center flex-align-items-center">
            <Typography variant="h6">{t('dashboard.circulatingMarketCap')}</Typography>
            <Typography variant="h5">
              {marketCap || marketCap === 0 ?
                (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(marketCap)
                ) : (
                  <Skeleton width="100px" />
              )}
            </Typography>
          </div>
        </div>
      </div>

      <div className="data-container">
        <div className="data-item flex flex-justify-content-between">
          <Typography variant="h5">{t('dashboard.balance')}</Typography>
          <Typography variant="h5">
            {
              !address ? '0 APD'
              :
                ohmBalance || ohmBalance === 0 ? trim(ohmBalance, 4) + ' APD'
                  : 
                  <Skeleton width="100px" />
            }
          </Typography>
        </div>

        <div className="data-item flex flex-justify-content-between">
          <Typography variant="h5">{t('dashboard.staked')}</Typography>
          <Typography variant="h5">
            {
              !address ? '0 uAPD'
              :
                sohmBalance && sohmBalance['0'] ?
                (
                  trim(sohmBalance['0'], 2) + ' uAPD'
                ) : (
                  <Skeleton width="100px" />
                )
            }
          </Typography>
        </div>

        <div className="data-item flex flex-justify-content-between">
          <Typography variant="h5">{t('dashboard.index')}</Typography>
          <Typography variant="h5">
            {
              currentIndex || currentIndex === 0
              ?
              (Number(currentIndex.toString())).toFixed(2) + ' APD'
              : 
              <Skeleton width="100px" />
            }
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
