import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useWeb3Context } from './web3Context'
import { ethers } from 'ethers'
import { IJsonRPCError } from 'src/slices/interfaces';
import { abi as IDOABI } from '../config/abi/redeem.json'
import { abi as ERC20 } from '../config/abi/erc20.json'
import { addresses } from 'src/constants';
import { abi as aSOHMMigration } from "../abi/aSOHMMigration.json";
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';
import { error } from "../slices/MessagesSlice";
import APDIcon from '../assets/ape.png'
// import { addTokenToWallet } from '../utils'
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { loadAccountDetails } from 'src/slices/AccountSlice';
import { loadAppDetails } from 'src/slices/AppSlice';

export default function useIdo() {

    const dispatch = useDispatch()
    const { address, provider, chainID } = useWeb3Context()
    const [alphaBalance, setAlphaBalance] = useState('0')
    const [white, setWhite] = useState()
    const [swap, setSwap] = useState()
    const [isApproving, setApproving] = useState(false)

    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async () => {
            if (provider && chainID) {
                try {
                    if(address) {
                        const aiceContract = new ethers.Contract(addresses[chainID].ALPHA_ADDRESS, ierc20Abi, provider)
                        const migarContract = new ethers.Contract(addresses[chainID].MIGRATION_ADDRESS, aSOHMMigration, provider)
                        const alphaBalance = await aiceContract.balanceOf(address)
                        const tokendecimals = await aiceContract.decimals()
                        setAlphaBalance(ethers.utils.formatUnits(alphaBalance, tokendecimals))
                        const isApprove = await aiceContract.allowance(address, addresses[chainID].MIGRATION_ADDRESS)
                        setApproving(+(isApprove.toString()) > 0)
                        const whiteList = await migarContract.whiteListed(address)
                        setWhite(whiteList)
                        const swap = await migarContract.senderInfo(address)
                        setSwap(+(swap.toString()) > 0)
                    }

                } catch (e) {
                    // console.log(e)
                }
            }
        },
        [address, provider, chainID],
    )

    const handlerMigrate = useCallback(async () => {
        let tx;	// Migrate
        try {
            const signer = provider.getSigner();
            const migrationContract = new ethers.Contract(addresses[chainID].MIGRATION_ADDRESS, aSOHMMigration, signer)
            tx = await migrationContract.migrate()
            dispatch(
                fetchPendingTxns({ txnHash: tx.hash, text: "Claim_MOS", type: "Claim_MOS" }),
            );
            await tx.wait();
            return tx
        } catch (e) {
            const rpcError = e
            if (rpcError.data) {
                dispatch(error(rpcError.data.message))
            } else {
                dispatch(error(rpcError.message))
            }
        } finally {
            if (tx) {
                // initData().then()
                await addTokenToWallet()
                dispatch(clearPendingTxn(tx.hash));
                dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
                dispatch(loadAppDetails({ networkID: chainID, provider }));
            }
        }
    }, [address, provider, chainID]
    )
    
    const handleApprove = useCallback(
        async () => {
            if (address && provider && chainID) {
                const signer = provider.getSigner()
                const asohContract = new ethers.Contract(addresses[chainID].ALPHA_ADDRESS, ierc20Abi, signer)
                let tx;
                try {
                    tx = await asohContract.approve(
                        addresses[chainID].MIGRATION_ADDRESS,
                        ethers.utils.parseUnits("1000000000", "gwei").toString()
                    )
                    dispatch(
                        fetchPendingTxns({ txnHash: tx.hash, text: "Approve_IND", type: "approve_ind" })
                    );
                    await tx.wait()
                    return tx
                } catch (e) {
                    const rpcError = e
                    if (rpcError.data) {
                        dispatch(error(rpcError.data.message))
                    } else {
                        dispatch(error(rpcError.message))
                    }
                } finally {
                    if (tx) {
                        // initData().then()
                        dispatch(clearPendingTxn(tx.hash));
                        dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
                        dispatch(loadAppDetails({ networkID: chainID, provider }));
                    }
                }
            }
        },
        [address, provider, chainID],
    )

    const addTokenToWallet = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: addresses[chainID].OHM_ADDRESS,
                            symbol: 'APD',
                            decimals: 9,
                            image: APDIcon,
                        },
                    },
                });
            } catch (e) {
                const rpcError = e
                if (rpcError.data) {
                    dispatch(error(rpcError.data.message))
                } else {
                    dispatch(error(rpcError.message))
                }
            }
        }
    }

    return { alphaBalance, isApproving, white, swap, initData, handleApprove, handlerMigrate }
}