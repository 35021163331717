
import './tabs.scss'

export default function Tabs({value, option, onChange}) {

  return (
    <div className="ape-tabs flex flex-align-items-center">
      {
        option.map(item => (
          <div className={`${value === item.value ? 'tab-item-active' : ''} tab-item`} onClick={() => onChange(item.value)}>{item.label}</div>
        ))
      }
      {/* <div className={`${current === 'doughnut' ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrent('doughnut')}>{t('doughnut.doughnut')}</div>
      <div className={`${current === 'swap' ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrent('swap')}>{t('doughnut.swap')}</div> */}
    </div>
  )
}
