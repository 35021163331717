import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";// TODO: this type definition needs to move out of BOND.
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { abi as OlympusStaking } from "../abi/OlympusStakingv2.json";

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider, time }: IBaseAddressAsyncThunk) => {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const ohmBalance = await ohmContract.balanceOf(address);
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, ierc20Abi, provider);
    const sohmContract3 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS3 as string, ierc20Abi, provider);
    const sohmContract6 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS6 as string, ierc20Abi, provider);
    const sohmContract9 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS9 as string, ierc20Abi, provider);
    const sohmBalance = await sohmContract.balanceOf(address);
    const sohmBalance3 = await sohmContract3.balanceOf(address);
    const sohmBalance6 = await sohmContract6.balanceOf(address);
    const sohmBalance9 = await sohmContract9.balanceOf(address);
    const staking = new ethers.Contract(addresses[networkID].STAKING_ADDRESS as string, OlympusStaking, provider);
    const staking3 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS3 as string, OlympusStaking, provider);
    const staking6 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS6 as string, OlympusStaking, provider);
    const staking9 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS9 as string, OlympusStaking, provider);
    const info = await staking.warmupInfo(address)
    const info3 = await staking3.warmupInfo(address)
    const info6 = await staking6.warmupInfo(address)
    const info9 = await staking9.warmupInfo(address)
    const warmBalance = await sohmContract.balanceForGons(info.gons)
    const warmBalance3 = await sohmContract3.balanceForGons(info3.gons)
    const warmBalance6 = await sohmContract6.balanceForGons(info6.gons)
    const warmBalance9 = await sohmContract9.balanceForGons(info9.gons)

    // const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, ierc20Abi, provider);
    // const wsohmBalance = await wsohmContract.balanceOf(address);

    return {
      balances: {
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        sohm: {
          0: ethers.utils.formatUnits(sohmBalance, "gwei"),
          3: ethers.utils.formatUnits(sohmBalance3, "gwei"),
          6: ethers.utils.formatUnits(sohmBalance6, "gwei"),
          9: ethers.utils.formatUnits(sohmBalance9, "gwei"),
          total: ethers.utils.formatUnits(sohmBalance + sohmBalance3 + sohmBalance6 + sohmBalance9, "gwei")
        },
        warmBalance: {
          0: ethers.utils.formatUnits(warmBalance, "gwei"),
          30: ethers.utils.formatUnits(warmBalance3, "gwei"),
          60: ethers.utils.formatUnits(warmBalance6, "gwei"),
          90: ethers.utils.formatUnits(warmBalance9, "gwei"),
        }
        // wsohmBalance: ethers.utils.formatUnits(wsohmBalance, "gwei"),
      },
    };
  },
);

interface IUserAccountDetails {
  balances: {
    warmBalance: string;
    ohm: string;
    sohm: string;
  };
  staking: {
    ohmStake: number;
    ohmUnstake: number;
  };
  bonding: {
    daiAllowance: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    let ohmBalance = 0;
    let sohmBalance = 0;
    let sohmBalance3 = 0;
    let sohmBalance6 = 0;
    let sohmBalance9 = 0;
    let stakeAllowance = {};
    let unstakeAllowance = {};
    let warmBalance = {}
    let GLAlock = 0
    let GLAlock3 = 0
    let GLAlock6 = 0
    let GLAlock9 = 0
    let wsohmBalance = 0

    if (addresses[networkID].OHM_ADDRESS) {
      const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
      ohmBalance = await ohmContract.balanceOf(address);
      const stakeAllowance0 = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
      const stakeAllowance3 = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS3);
      const stakeAllowance6 = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS6);
      const stakeAllowance9 = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS9);
      stakeAllowance = {
        0: stakeAllowance0,
        30: stakeAllowance3,
        60: stakeAllowance6,
        90: stakeAllowance9,
      }
      // stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    }

    if (addresses[networkID].SOHM_ADDRESS) {
      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider);
      const sohmContract3 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS3 as string, sOHMv2, provider);
      const sohmContract6 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS6 as string, sOHMv2, provider);
      const sohmContract9 = new ethers.Contract(addresses[networkID].SOHM_ADDRESS9 as string, sOHMv2, provider);
      sohmBalance = await sohmContract.balanceOf(address);
      sohmBalance3 = await sohmContract3.balanceOf(address);
      sohmBalance6 = await sohmContract6.balanceOf(address);
      sohmBalance9 = await sohmContract9.balanceOf(address);
      // sohmBalance = await sohmContract.balanceOf(address);

      
      const stakeAllowance0 = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
      const stakeAllowance3 = await sohmContract3.allowance(address, addresses[networkID].STAKING_ADDRESS3);
      const stakeAllowance6 = await sohmContract6.allowance(address, addresses[networkID].STAKING_ADDRESS6);
      const stakeAllowance9 = await sohmContract9.allowance(address, addresses[networkID].STAKING_ADDRESS9);

      unstakeAllowance = {
        0: stakeAllowance0,
        30: stakeAllowance3,
        60: stakeAllowance6,
        90: stakeAllowance9,
      }
      // sohmBalance = await sohmContract.balanceOf(address);
      const staking = new ethers.Contract(addresses[networkID].STAKING_ADDRESS as string, OlympusStaking, provider);
      const staking3 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS3 as string, OlympusStaking, provider);
      const staking6 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS6 as string, OlympusStaking, provider);
      const staking9 = new ethers.Contract(addresses[networkID].STAKING_ADDRESS9 as string, OlympusStaking, provider);
      // const info = await staking.warmupInfo(address)
      const info3 = await staking3.warmupInfo(address)
      const info6 = await staking6.warmupInfo(address)
      const info9 = await staking9.warmupInfo(address)
      const epoch = await staking.epoch()
      // epoch.number expiry  
      // GLAlock = (Number(info.expiry) > Number(epoch.number))

      const epoch3 = await staking3.epoch()
      const epoch6 = await staking6.epoch()
      const epoch9 = await staking9.epoch()
      GLAlock3 = (Number(info3.expiry) - Number(epoch3.number))
      GLAlock6 = (Number(info6.expiry) - Number(epoch6.number))
      GLAlock9 = (Number(info9.expiry) - Number(epoch9.number))
      let warmBalance3, warmBalance6, warmBalance9
      // if(+info3.gons === 0) {
      //   warmBalance3 = 0
      // } else {
      //   warmBalance3 = await sohmContract3.balanceForGons(info3.gons)
      // }
      warmBalance3 = +info3.gons === 0 ? 0 : await sohmContract3.balanceForGons(info3.gons)
      warmBalance6 = +info6.gons === 0 ? 0 : await sohmContract6.balanceForGons(info6.gons)
      warmBalance9 = +info9.gons === 0 ? 0 : await sohmContract9.balanceForGons(info9.gons)
      warmBalance = {
        30: ethers.utils.formatUnits(warmBalance3, "gwei"),
        60: ethers.utils.formatUnits(warmBalance6, "gwei"),
        90: ethers.utils.formatUnits(warmBalance9, "gwei"),
      }
    }

    // if (addresses[networkID].WSOHM_ADDRESS) {
      // const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, ierc20Abi, provider);
      // wsohmBalance = await wsohmContract.balanceOf(address);
    // }

    return {
      balances: {
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        // sohm: ethers.utils.formatUnits(sohmBalance, "gwei"),
        sohm: {
          0: ethers.utils.formatUnits(sohmBalance, "gwei"),
          30: ethers.utils.formatUnits(sohmBalance3, "gwei"),
          60: ethers.utils.formatUnits(sohmBalance6, "gwei"),
          90: ethers.utils.formatUnits(sohmBalance9, "gwei"),
          total: ethers.utils.formatUnits(+sohmBalance + +sohmBalance3 + +sohmBalance6 + +sohmBalance9, "gwei")
        },
        warmBalance,
        wsohmBalance: ethers.utils.formatUnits(wsohmBalance, "gwei"),
      },
      staking: {
        ohmStake: stakeAllowance,
        ohmUnstake: unstakeAllowance,
      },
      lock: {
        GLAlock: GLAlock,
        30: GLAlock3,
        60: GLAlock6,
        90: GLAlock9,
      },
      bonding: {
        daiAllowance: [0, 0, 0],
      }
    };
  },
);

export interface IUserBondDetails {
  allowance: object;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: {
          lp: 0,
          notLp: 0
        },
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    const wbnbContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20Abi, provider);


    let interestDue, pendingPayout, bondMaturationBlock;
    let interestDue1, pendingPayout1, bondMaturationBlock1;

    const bondDetails = await bondContract.bondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutFor(address);
    pendingPayout1 = await bondContract.pendingPayoutForReward(address);

    const bondRewardDetails = await bondContract.rewardBondInfo(address);
    interestDue1 = bondRewardDetails.payout / Math.pow(10, 9);
    bondMaturationBlock1 = +bondRewardDetails.vesting + +bondRewardDetails.lastBlock;

    let allowance,
      balance = 0;
    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID));
    balance = await reserveContract.balanceOf(address);
    const avaxBalance = await provider.getBalance(address);
    let wbnbAllowance
    if (bond.isLP) {
      wbnbAllowance = await wbnbContract.allowance(address, bond.getAddressForBondHelper(networkID));
    } else {
      // if (bond.name == 'MIM') {
      //   wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // } else {
      wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // }
    }
    let wbnbBalance = bond.name == 'aFTMb' ? ethers.utils.formatUnits(balance, 18) : ethers.utils.formatUnits((await wbnbContract.balanceOf(address)), 18)
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: {
        lp: Number(wbnbAllowance),
        notLp: Number(allowance)
      },
      balance: [wbnbBalance, balanceVal, ethers.utils.formatEther(avaxBalance)],
      interestDue,
      interestDue1,
      bondMaturationBlock,
      bondMaturationBlock1,
      pendingPayout1: ethers.utils.formatUnits(pendingPayout1, "gwei"),
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

export const calculateUserSuperBondDetails = createAsyncThunk(
  "account/calculateUserSuperBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: {
          lp: 0,
          notLp: 0
        },
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    const wbnbContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20Abi, provider);


    let interestDue, pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.rewardBondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutForReward(address);

    let allowance,
      balance = 0;
    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID));
    balance = await reserveContract.balanceOf(address);
    const avaxBalance = await provider.getBalance(address);
    let wbnbAllowance
    if (bond.isLP) {
      wbnbAllowance = await wbnbContract.allowance(address, bond.getAddressForBondHelper(networkID));
    } else {
      // if (bond.name == 'MIM') {
      //   wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // } else {
      wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // }
    }
    let wbnbBalance = ethers.utils.formatUnits((await wbnbContract.balanceOf(address)), 18)
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: {
        lp: Number(wbnbAllowance),
        notLp: Number(allowance)
      },
      balance: [wbnbBalance, balanceVal, ethers.utils.formatEther(avaxBalance)],
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);
interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  superBonds: { [key: string]: IUserBondDetails };
  balances: {
    ohm: string;
    sohm: string;
    warmBalance: string;
  };
  loading: boolean;
}
const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  superBonds: {},
  balances: { ohm: "", sohm: "", warmBalance: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        //   console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        //   console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        // console.log(error);
        state.loading = false;
      })
      .addCase(calculateUserSuperBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserSuperBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.superBonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserSuperBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        // console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
