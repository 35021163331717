import { ethers } from 'ethers'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { addresses } from 'src/constants';
import { IJsonRPCError } from 'src/slices/interfaces';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from './web3Context';
import { abi as INVITEABI } from '../abi/invite.json'
import { error } from "../slices/MessagesSlice";
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useInvite() {

    const { address, provider, chainID } = useWeb3Context()
	const [inviters, setInviters] = useState([])
	const [inviterAddress, setInviterAddress] = useState('')
	const [users, setUsers] = useState({})
	const [inviteNum, setInviteNum] = useState()
    
    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async () => {

            if (provider && chainID && address) {
                try {
					const inviteContract = new ethers.Contract(addresses[chainID].INVITE_ADDRESS, INVITEABI, provider)
					const inviteContract2 = new ethers.Contract(addresses[chainID].INVITE_ADDRESS2, INVITEABI, provider)
                    // const inviter = await inviteContract.queryInvite(address)
                    // setInviters(inviter)
                    const add = await inviteContract.superiorAddress(address)
                    setInviterAddress(add === '0x0000000000000000000000000000000000000000' ? '' : add)

                    const users = await inviteContract2.users(address)
                    setUsers(users)

                    const inviteNum = await inviteContract.inviteNum(address)
                    setInviteNum(inviteNum)
                    
                } catch (error) {
                    // console.log(error)
                }
            }
        },
        [address, provider, chainID])

    const BindInviter = useCallback(
        async (add) => {
            const signer = provider.getSigner()
            const inviteContract = new ethers.Contract(addresses[chainID].INVITE_ADDRESS, INVITEABI, signer)
            let result
            try {
                result = await inviteContract.inviteAddress(add)
                await result.wait()
                return result
            } catch (e: any) {
                if (e.data) {
                    openWarningNotification(e.data.message)
                } else {
                    openWarningNotification(e.message)
                }
            } finally {
                if(result) {
                    openSuccessNotification("Successfully")
                    initData()
                }
            }
        },
        [address, provider, chainID],
    )

    return { inviteNum, users, inviters, inviterAddress, BindInviter }
}
