import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@material-ui/lab";
import * as echarts from 'echarts';
import { ethers } from "ethers";
import {
	Typography,
	Button
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useWeb3Context } from "../../hooks/web3Context";
import { useTranslation } from 'react-i18next';
import { addresses } from "src/constants";
import { formatNum } from '../../utils';
import { openWarningNotification } from 'src/utils/tip'
import { trim } from "../../helpers";

import useIdo from "src/hooks/useIdo";
import useRedeem from "src/hooks/useRedeem";

import Loading from 'src/components/LoadingDialog/LoadingDialog'

import Arrow from '../../assets/arrow.png'
import Clock from '../../assets/clock.png'
import USDT from '../../assets/usdt.png'
import APD from '../../assets/ape.png'
import Swap from '../../assets/tokenSwap.png'
import Yes from '../../assets/check-yes.png'
import No from '../../assets/check-no.png'
import FinishApp from '../../assets/images/finish-app.png'
import Finish from '../../assets/images/finish.png'

import "./ido.scss";
import "./ido.app.scss";

function Ido() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
	const isSmallerScreen = useMediaQuery("(max-width: 958px)")
	const isSmallScreen = useMediaQuery("(max-width: 600px)")
	const { provider, chainID, address, connect } = useWeb3Context();

	const dispatch = useDispatch();
	const { goal, price, white, participated, allowance, saleAmount, DoClaim, DoApprove, getTokenBalance } = useIdo()
  const { alphaBalance, swap, isApproving, initData, handleApprove, handlerMigrate } = useRedeem()
	
	const [pending, setPending] = useState(false)
	const [swapPending, setSwapPending] = useState(false)
	const [tokenBalance, setTokenBalance] = useState()
	const [progressValue, setProgressValue] = useState(0)
	const [usdtValue, setUsdtValue] = useState(500)
	const [apdValue, setApdValue] = useState(0)

	const [finished, setFinished] = useState(true)
  
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState('loading')
  const [loadingTitle, setLoadingTitle] = useState('')

	let myChart = null

	useEffect(() => {
		if (address && chainID) {
			getTokenBalance(addresses[chainID].USDT_ADDRESS).then(bal => {
				bal && setTokenBalance(bal)
			}).catch(e => { 
				// console.log(e)
			})
		}
	}, [address, provider, chainID])

	// useEffect(() => {
	// 	if(saleAmount && goal && saleAmount !== 0 && goal !== 0) {
	// 		// const value = ethers.utils.formatEther(saleAmount) / ethers.utils.formatEther(goal) * 100
	// 		// setProgressValue(value.toFixed(2))
	// 	}
	// 	// initChart()
	// }, [saleAmount, goal, currentLang])

	const buy = async () => {
		if(pending) return
		setPending(true)
		if (allowance > 0) {
			if(usdtValue > Number(ethers.utils.formatEther(tokenBalance))) {
				setPending(false)
				return openWarningNotification(t('common.insufficient'))
			}
			DoClaim(Number(usdtValue)).then(tx => {
				setPending(false)
			})
		} else {
			DoApprove().then(tx => {
				setPending(false)
			})
		}
	}

	useEffect(() => {
		setApdValue((usdtValue / price))
	}, [price])

	const usdtChange = (e) => {
		const { value } = e.target
		const inputValue = value.replace(/\D/g, '')
		setUsdtValue(inputValue)
		setApdValue((Number(inputValue) / price))
	}

	const inputBlur = () => {
		const max = 1000
		const min = 500

		if(Number(usdtValue) <= 500) {
			setUsdtValue(min)
			setApdValue((min / price).toFixed(2))
		}

		if(Number(usdtValue) >= 1000) {
			setUsdtValue(max)
			setApdValue((max / price).toFixed(2))
		}
	}

	const handleSwap = async () => {
    setVisible(true)
		setSwapPending(true)
		if (isApproving) {
    	setLoadingTitle(t('loading.swap'))
			handlerMigrate()
				.then(res => {
					if(res) {
						initData().then((result) => {
							setVisible(false)
						}).catch(() => {
							setStatus('error')
						})
					} else {
						setStatus('fail')
					}
				})
				.finally(() => {
					setSwapPending(false)
				})
		} else {
    	setLoadingTitle(t('loading.approve'))
			handleApprove()
				.then(res => {
					if(res) {
						initData().then((result) => {
							setVisible(false)
						}).catch(() => {
							setStatus('error')
						})
					} else {
						setStatus('fail')
					}
				})
				.finally(() => {
					setSwapPending(false)
				})
		}
	}

  const handleClose = () => {
    setVisible(false)
    setStatus('loading')
  }

	// useEffect(() => {
	// 	initChart()
	// }, [isSmallerScreen])

	const initChart = () => {
		const formatGoal = goal
		// const formatGoal = goal ? goal / Math.pow(10, 18) : 0
    let element = document.getElementById('ido-chart');
		if(!element) return
		element.style.width = isSmallerScreen ? window.innerWidth + 'px' : '100%'
		echarts.init(element).dispose()
		myChart = echarts.init(element);
    const option = {
      series: 
        {
          type: 'gauge',
					center: ['50%', '80%'],
					radius: '140%',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: goal ? formatGoal : 100,
          // splitNumber: 12,
          itemStyle: {
            color: '#58D9F9',
            width: 20,
            shadowColor: 'rgba(0,138,255,0.45)',
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
					// grid: {
					// 	left: 0,
					// 	right: 0
					// },
          progress: {
            show: true,
            roundCap: false,
            width: 32
          },
          pointer: {
            show: false,
          },
          axisLine: {
            roundCap: false,
            lineStyle: {
              width: 32,
							opacity: 0.5
            }
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          title: {
            show: false
          },
          detail: {
            // show: false,
            // backgroundColor: '#fff',
            borderColor: '#999',
            // borderWidth: 2,
            // width: '60%',
            lineHeight: 40,
            height: 40,
            // borderRadius: 8,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + ((value && goal) ? (value / formatGoal * 100).toFixed(2) : 0) + '%}\n{unit|}' + t('ido.progress')
            },
						textStyle: {
							fontSize: 20
						},
            rich: {
              value: {
                fontSize: 48,
                fontWeight: 'bolder',
                color: '#FFF'
              },
              unit: {
                fontSize: 24,
                color: '#A1A1A1',
                padding: [0, 0, 0, 10]
              }
            }
          },
          data: [
            {
              value: (saleAmount && goal) ? saleAmount : 0
            }
          ]
        }
      
    };
		option && myChart.setOption(option);
  }

	return (
		<div className="ido-info-view flex flex-column">
      <Loading visible={visible} title={loadingTitle} close={handleClose} status={status} />
			<div className="ido-top">
				<Typography variant="h3" className="Poppins" style={{fontWeight: 'bold', marginTop: 12}}>
					Token Swap
				</Typography>
			</div>

			<div className={`ido-bot flex-wrap flex flex-justify-content-center ${isSmallScreen ? 'flex-align-items-start' : 'flex-align-items-center'}`}>
				{finished && <div className="bot-right">
					<div className="swap-container">
					{/* {isSmallScreen && <div className="swap-title">Token Swap</div>} */}

					<div className="card-container flex">
						<div className="swap-card flex flex-column flex-justify-content-between">
							<img src={Swap} alt="" />
							<Typography variant="h6">alpha APD</Typography>
							<Typography className="card-text">{alphaBalance ? (+alphaBalance).toFixed(2) : '0.00'} aAPD</Typography>
						</div>

						<div className="swap-arrow flex flex-justify-content-center flex-align-items-center">
							<img src={Arrow} alt="" />
						</div>

						<div className="swap-card flex flex-column flex-justify-content-between">
							<img src={Swap} alt="" />
							<Typography variant="h6">APD</Typography>
							<Typography className="card-text">{alphaBalance ? (+alphaBalance).toFixed(2) : '0.00'} APD</Typography>
						</div>
					</div>

					{!isApproving ? 
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleSwap()
              }}
              disabled={swapPending || swap}
              className="redeemBtn transaction-button"
              block={true}
						>
                {swapPending ? <CircularProgress size={20} /> : t('redeem.approve')}
            </Button>
            :
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleSwap()
              }}
              disabled={swapPending || swap}
              className="redeemBtn transaction-button"
              block={true}
						>
                {swapPending ? <CircularProgress size={20} /> : 'Swap'}
            </Button>
          }
					</div>
				</div>}
			</div>
		</div>
	)
}

export default Ido
