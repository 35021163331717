import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as OlympusStaking } from "../abi/OlympusStakingv2.json";
import { abi as StakingHelper } from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances, loadAccountDetails } from "./AccountSlice";
import { loadAppDetails } from './AppSlice'
import { error } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID, time }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    let sohmAddress, stakingAddress, stakingHelpAddress
    switch (time) {
      case 0:
        sohmAddress = addresses[networkID].SOHM_ADDRESS
        stakingAddress = addresses[networkID].STAKING_ADDRESS
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS
        break;
      case 30:
        sohmAddress = addresses[networkID].SOHM_ADDRESS3
        stakingAddress = addresses[networkID].STAKING_ADDRESS3
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS3
        break;
      case 60:
        sohmAddress = addresses[networkID].SOHM_ADDRESS6
        stakingAddress = addresses[networkID].STAKING_ADDRESS6
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS6
        break;
      case 90:
        sohmAddress = addresses[networkID].SOHM_ADDRESS9
        stakingAddress = addresses[networkID].STAKING_ADDRESS9
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS9
        break;
      default:
        break;
    }

    const signer = provider.getSigner();
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, signer);
    const sohmContract = new ethers.Contract(sohmAddress as string, ierc20Abi, signer);
    let approveTx;
    try {
      if (token === "ohm") {
        approveTx = await ohmContract.approve(
          stakingHelpAddress,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
        );
      } else if (token === "sohm") {
        approveTx = await sohmContract.approve(
          stakingAddress,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
        );
      }
      const text = "Approve " + (token === "ohm" ? "Staking" : "Unstaking");
      const pendingTxnType = token === "ohm" ? "approve_staking" : "approve_unstaking";
      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));

      await approveTx.wait();
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
    return dispatch(
      loadAccountDetails({ networkID, address, provider })
      // fetchAccountSuccess({
      //   staking: {
      //     ohmStake: +stakeAllowance,
      //     ohmUnstake: +unstakeAllowance,
      //   },
      // }),
    );
  },
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async ({ action, value, provider, address, networkID, time = 0 }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    let stakingAddress, stakingHelpAddress

    switch (time) {
      case 0:
        stakingAddress = addresses[networkID].STAKING_ADDRESS
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS
        break;
      case 30:
        stakingAddress = addresses[networkID].STAKING_ADDRESS3
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS3
        break;
      case 60:
        stakingAddress = addresses[networkID].STAKING_ADDRESS6
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS6
        break;
      case 90:
        stakingAddress = addresses[networkID].STAKING_ADDRESS9
        stakingHelpAddress = addresses[networkID].STAKING_HELPER_ADDRESS9
        break;
      default:
        break;
    }

    const signer = provider.getSigner();
    const staking = new ethers.Contract(stakingAddress as string, OlympusStaking, signer);
    const stakingHelper = new ethers.Contract(
      stakingHelpAddress as string,
      StakingHelper,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: value,
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      if (action === "stake") {
        uaData.type = "stake";
        stakeTx = await stakingHelper.stake(ethers.utils.parseUnits(value, "gwei"));
      } else {
        uaData.type = "unstake";
        stakeTx = await staking.unstake(ethers.utils.parseUnits(value, "gwei"), true);
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
      await stakeTx.wait();
      dispatch(loadAppDetails({ networkID, provider }));
      return dispatch(loadAccountDetails({ address, networkID, provider }));
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const doClaim = createAsyncThunk(
  "stake/doClaim",
  async ({ provider, address, networkID, time= 0 }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    let stakingAddress
    switch (time) {
      case 0:
        stakingAddress = addresses[networkID].STAKING_ADDRESS
        break;
      case 30:
        stakingAddress = addresses[networkID].STAKING_ADDRESS3
        break;
      case 60:
        stakingAddress = addresses[networkID].STAKING_ADDRESS6
        break;
      case 90:
        stakingAddress = addresses[networkID].STAKING_ADDRESS9
        break;
      default:
        break;
    }
    const staking = new ethers.Contract(stakingAddress as string, OlympusStaking, signer);
    let stakeTx;
    try {
      stakeTx = await staking.claim(address);
      const pendingTxnType = "ClaimsKARMA";
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "Claim sIND", type: pendingTxnType }));
      await stakeTx.wait();
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      dispatch(error(rpcError.message));
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(loadAccountDetails({ address, networkID, provider }));
    dispatch(loadAppDetails({ networkID, provider }));
  },
);
